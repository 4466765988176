import { useState, useContext, useEffect } from 'react';
import { Link, json, useNavigate } from "react-router-dom";
import { PasswordShow, validateForm, ButtonSpinner, initialFormState, showAlertMsg } from "../components/CommonMethod";
import { fetchData, LOGIN } from "./Service";
import { Context } from '../components/Context';
import ForgetPassword from '../modules/Auth/ForgetPassword';
import { isGuest, logoutGuest } from './GuestHelper';

function Login() {

    const modalHide = (modalId) => {
        document.querySelector(`#${modalId} .btn-close`).click();
    }

    const navigate = useNavigate();
    const [context, setContext] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            login(e)
        }
    }
    const login = async (e) => {
        let formdata = new FormData(document.getElementById('loginFormModal'));
        if (validateForm(e, "loginFormModal")) {
            setTinyloader(true);
            let guest = isGuest()
            formdata.append('guest', JSON.stringify(guest))
            await fetchData(LOGIN, 'POST', formdata, false, true, (res) => {
                setTinyloader(false);
                if (res.records) {
                    logoutGuest()
                    initialFormState('loginFormModal')
                    modalHide('loginModal')
                    localStorage.setItem('waae-token', res.records.access_token)
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))
                    if (res.records.user_type === '2') {
                        navigate(`/`);
                    } else {
                        navigate('/cms')
                    }
                } else {
                    if (res.errors && (res.errors.password || res.errors.email)) {
                        showAlertMsg({ error: "These credentials do not match our records." })
                    }
                    modalHide('loginModal')
                }
            }, false, false, 'loginFormModal');
        };
    }


    useEffect(() => {

    }, [context])
    return (<>
        <div className="modal fade" id="loginModal" tabIndex="-1" data-bs-backdrop="static">
            <div className="modal-dialog">
                <form className="modal-content border border-2 border-blue p-2 rounded-4 needs-validation" id="loginFormModal" noValidate autoComplete='off'>
                    <div className="modal-header border-0">
                        <h3 className="modal-title fw-bold text-blue">Log in</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => initialFormState('loginFormModal')} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-4">
                            <input type="hidden" name="front" id="front" value="front" />
                            <input type="email" name="email" id="email" placeholder="Email address" className="form-control form-control-md" onKeyUp={(e) => changeHandler(e)} autoComplete="username" required />
                            <div className="invalid-feedback">
                                The email field is required and must be a valid email address.
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="password-wrapper">
                                <input type="password" placeholder="Password" name='password' id="passwords" onKeyUp={(e) => changeHandler(e)} className="form-control form-control-md" autoComplete="current-password" required />
                                <div className="invalid-feedback">
                                    The password field is required.
                                </div>
                                <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'passwords')}>
                                    <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                                </button>
                            </div>
                        </div>
                        <Link to="#forgotPasswordModal" className="fw-bold text-dim" data-bs-toggle="modal" title="Log in">Forgot my password</Link>
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-theme-primary btn-lg minw-110 mb-3 mb-sm-2 me-auto me-sm-0 order-sm-1" onClick={(e) => login(e)} disabled={tinyloader}>
                            <ButtonSpinner load={tinyloader} btnName="Log in" />
                        </button>
                        <div className="me-auto mb-2 text-muted order-0">
                            Don't have an account? <Link to="/sign-up" className="fw-medium" onClick={() => modalHide('loginModal')}>Sign up</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <ForgetPassword />
    </>)
}
export default Login