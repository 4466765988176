import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCountryFlagSrc } from "../../components/CommonMethod";
import { fetchData, GROUP_LIST } from "../../components/Service";
import { PageNavigation } from "../../components/DataTable"

const Groups = () => {
    window.document.title = "Manage Groups";

    const [groupsData, setGroupsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        fetchData(`${GROUP_LIST}?page=${currentPage}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setGroupsData(res.records ?? [])
            } else {
                setGroupsData(false)
            }
        })
    }, [currentPage]);

    return (
        <div className="container-fluid">
             <div className="page-head sticky_top_padding">
            <div className="row g-2 align-items-center">
                <div className="col-lg-6 col-6 mb-3">
                    <h1 className="h3 text-dim mb-0">Manage Groups</h1>
                </div>
                <div className="col-lg-6 col-6 mb-3">
                    <Link to="/cms/create-group" role="button" className="btn btn-green btn-lg px-3 float-end">+ Create new group</Link>
                </div>
            </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mt-1">
                {groupsData && groupsData.data && groupsData.data.length > 0 && groupsData.data.map((item, i) => {
                    return (
                        <div className="col" key={i}>
                            <div className={`card border-blue-hover rounded-3 p-4 ${item.status === 'block' ? 'bg-grey2' : ''}`}>
                                <div className="d-flex justify-content-between">
                                    <div className="pe-3">
                                        <h4 className="fw-bold">{item.group_name}</h4>
                                        <p className="fw-bold">Enrollment size: {item.user_limit} students</p>
                                    </div>
                                    <div className="ms-auto">
                                        <Link to="/cms/manage-group" state={{ item }} className="btn btn-outline-dark mmwh-48 d-inline-flex justify-content-center align-items-center" title="Edit">
                                            <svg className="icon ts-1p5"><use href="#icon_pencil"></use></svg>
                                        </Link>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="mmwh-36 me-2">
                                        <img src={`../images/vector/${getCountryFlagSrc(item.country_code)}`} className="w-100" alt="Flag" />
                                    </div>
                                    <span className="text-uppercase text-dim fs-18 fw-bold">{item.country_name}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}

                {groupsData && groupsData.total ? <PageNavigation active_page={groupsData.current_page - 1} per_page={groupsData.per_page} filter_recoards={groupsData.total} setCurrentPage={setCurrentPage} /> : ''}
            </div>

            {!groupsData.data || groupsData.data.length === 0 && <div className="row mt-1"><h4 className="fw-bold text-danger text-center">Groups Not Found</h4></div>}
        </div>
    )
}

export default Groups;