import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateForm, ButtonSpinner, initialFormState, stopBackgroundScroll, noImageHandle } from "../../components/CommonMethod";
import { DROPDOWN_DATA, fetchData, ASSIGNMENT_UPDATE, ASSIGNMENT_CREATE, ASSIGNMENT_GROUP_SECTION, GET_SINGLE_ASSIGNMENT, MY_WORKBOOKS_FILTER_ITEM, MY_WORKBOOKS, ASSET_ENDPOINT, ASSIGNMENT_WORKBOOK } from "../../components/Service";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { now } from "lodash";
import { Context } from "../../components/Context";
import { addDays } from "date-fns";
import { PageNavigation } from "../../components/DataTable";
import WorkbookFilter, { FilterTopic } from "../../components/WorkbookFilter";

const CreateAssignment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let assignmentInfo = location.state

    let title = assignmentInfo ? "Update Assignment" : "Create Assignment"

    window.document.title = title;

    const [context] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);
    const [lateSubmisstion, setLateSubmisstion] = useState(assignmentInfo && assignmentInfo.allow_late_submission ? assignmentInfo.allow_late_submission : 'yes');
    const [deadlineDate, setDeadlineDate] = useState(assignmentInfo && assignmentInfo.deadline ? new Date(assignmentInfo.deadline) : now());
    const [assignmentStep, setAssignmentStep] = useState('step1')
    const [assignmentDetails, setAssignmentDetails] = useState()
    const [groupSectionList, setGroupSectionList] = useState([]);
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [filterItem, setFilterItem] = useState('')
    const [filter, setFilter] = useState(now)
    const [workbookIds, setWorkbookIds] = useState([])

    let group_code = context && context.auth && context.auth.group_details && context.auth.group_details.group_code;
    let group_id = context && context.auth && context.auth.group_details && context.auth.group_details.group_id;

    const createAssignment = (e, step) => {
        if (step === 'step1') {
            let formdata = new FormData(document.getElementById('addAssignmentForm'))
            formdata.append('group_code', group_code)

            if (validateForm(e, 'addAssignmentForm') && group_code) {
                setTinyloader(true);

                fetchData(assignmentInfo ? ASSIGNMENT_UPDATE : ASSIGNMENT_CREATE, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);

                    if (res.records) {
                        navigate('../update-assignment', { state: { ...res.records, redirectTo: 'groupSection' } })
                    }
                })
            }
        } else if (step === 'step2') {
            let formdata = new FormData(document.getElementById('assignGroupToAssignmentForm'))
            formdata.append('assignment_id', assignmentInfo && assignmentInfo.id)
            formdata.append('group_code', group_code)

            setTinyloader(true);

            fetchData(ASSIGNMENT_GROUP_SECTION, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);

                if (res.success) {
                    let workbook_btn = document.getElementById("workbookTab")
                    if (workbook_btn) workbook_btn.click()
                }
            })
        } else if (step === 'step3') {
            let formdata = new FormData()
            formdata.append('assignment_id', assignmentInfo && assignmentInfo.id)
            formdata.append('group_code', group_code)
            formdata.append('workbook_id', workbookIds)

            setTinyloader(true);

            fetchData(ASSIGNMENT_WORKBOOK, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
            })
        }
    }

    const handleCheckboxChange = (e) => {
        e.target.checked ? setWorkbookIds([...workbookIds, e.target.value]) : setWorkbookIds(workbookIds.filter(i => i !== e.target.value));
    }

    useEffect(() => {
        if (!assignmentInfo) {
            let assignment_box_btn = document.getElementById("assignmentDetailBoxBtn")
            if (assignment_box_btn) assignment_box_btn.click()
            let group_section_box = document.getElementById("groupSectionBox")
            if (group_section_box) group_section_box.classList.remove('active')
            initialFormState("addAssignmentForm")
        }

        if (assignmentInfo) {
            fetchData(DROPDOWN_DATA + '?type=section&section_group_id=' + group_id, 'GET', '', true, false, (res) => {
                if (res.records) {
                    setGroupSectionList(res.records.group_sections)
                }
            })

            fetchData(GET_SINGLE_ASSIGNMENT, 'POST', { id: assignmentInfo.id }, true, false, (res) => {
                if (res.records) {
                    setAssignmentDetails(res.records)

                    let workbook_ids_string = res.records.assignment_workbook.toString()
                    let workbook_ids_array = workbook_ids_string.split(',')
                    setWorkbookIds(workbook_ids_array)
                }
            })
        }

        if (assignmentInfo && assignmentInfo.redirectTo === 'groupSection') {
            document.getElementById('groupSectionTab').click();
        }
    }, [assignmentInfo, group_id])

    const filterToggle = () => {
        document.getElementById('asideFilterBox').classList.toggle('active');
        stopBackgroundScroll("asideFilterBox");
    }

    useEffect(() => {
        fetchData(MY_WORKBOOKS_FILTER_ITEM, 'get', '', true, false, (res) => {
            if (res.records) {
                setFilterItem(res.records ?? [])
            }
        });
    }, [])

    useEffect(() => setCurrentPage(1), [filter])

    useEffect(() => {
        if (context && context.auth) {
            let formData = new FormData(document.getElementById('filterForm'))
            fetchData(`${MY_WORKBOOKS}?page=${currentPage}`, 'POST', formData, true, true, (res) => {
                if (res.records) {
                    setData(res.records ?? [])
                }
            });
        }
    }, [context, currentPage, filter])

    return (
        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                <li className="breadcrumb-item"><Link to="/cms/assignments">Manage Assignments</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                {assignmentInfo && assignmentInfo.title && <li className="breadcrumb-item text-green" aria-current="page">{assignmentInfo && assignmentInfo.title}</li>}
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center">
                    <div className="col-lg-6 col-sm mb-3 col-6 mb-3">
                        <h1 className="h3 text-dim mb-0">{title}</h1>
                    </div>
                    <div className="col-lg-6 col-auto mb-3 col-6  mb-3">
                        <button type="button" className="btn btn-theme-primary btn-lg float-end" onClick={(e) => createAssignment(e, assignmentStep)} disabled={tinyloader}>
                            <ButtonSpinner load={tinyloader} btnName={assignmentStep === 'step1' ? 'Save' : (assignmentStep === 'step2' ? 'Save sections' : 'Save workbooks')} />
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <nav>
                        <div className="nav nav-tabs nav-bar mb-2" id="nav-tab" role="tablist">
                            <button id="assignmentDetailBoxBtn" className="nav-link active" data-bs-toggle="tab" data-bs-target="#assignmentDetailBox" type="button" role="tab" aria-selected="true" onClick={() => setAssignmentStep('step1')}>Details</button>
                            {assignmentInfo && <button className="nav-link" data-bs-toggle="tab" data-bs-target="#groupSectionBox" type="button" role="tab" aria-selected="false" id="groupSectionTab" onClick={() => setAssignmentStep('step2')}>Select Sections</button>}
                            {assignmentInfo && <button className="nav-link" data-bs-toggle="tab" data-bs-target="#workbookBox" type="button" role="tab" aria-selected="false" id="workbookTab" onClick={() => setAssignmentStep('step3')}>Select Workbooks</button>}
                        </div>
                    </nav>
                    <div className="tab-content pt-4">
                        <div className="tab-pane fade show active" id="assignmentDetailBox" role="tabpanel" tabIndex="0">
                            <form action="" className="needs-validation" id="addAssignmentForm" noValidate autoComplete="off">
                                <input type="hidden" name="id" defaultValue={assignmentInfo && assignmentInfo.id} />
                                <div className="form-floating maxw-360 mb-4">
                                    <input type="text" className="form-control form-control-md" defaultValue={assignmentInfo && assignmentInfo.title} placeholder="Assignment Title" name="title" id="title" required />
                                    <div className="invalid-feedback">
                                        The assignment title field is required .
                                    </div>
                                    <label>Assignment Title<span className="text-danger">*</span></label>
                                </div>

                                <div className="form-floating maxw-360 mb-4">
                                    <DatePicker
                                        selected={deadlineDate}
                                        onChange={(date) => setDeadlineDate(date)}
                                        className="form-control form-control-md"
                                        minDate={new Date()}
                                        maxDate={addDays(new Date(), 15)}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        name="deadline"
                                        showTimeSelect
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        The deadline field is required .
                                    </div>
                                    <label className="scale-label">Deadline<span className="text-danger">*</span></label>
                                </div>

                                <div className="form-floating maxw-360 mb-4">
                                    <textarea className="form-control form-control-md" defaultValue={assignmentInfo && assignmentInfo.instruction} placeholder="Instructions" name="instruction" id="instruction" style={{ minHeight: "100px" }} required></textarea>
                                    <div className="invalid-feedback">
                                        The instructions field is required .
                                    </div>
                                    <label>Instructions<span className="text-danger">*</span></label>
                                </div>

                                <div className="d-flex align-items-center">
                                    <label className="text-muted fw-bold me-4">Allow Late Submission</label>
                                    <label className="form-check form-check-custom">
                                        <input className="form-check-input" type="radio" name="allow_late_submission" value="yes" checked={lateSubmisstion === 'yes'} onChange={(e) => setLateSubmisstion(e.target.value)} />
                                        <div className="form-check-label fw-bold">Yes</div>
                                    </label>
                                    <label className="form-check form-check-custom ms-3">
                                        <input className="form-check-input" type="radio" name="allow_late_submission" value="no" checked={lateSubmisstion === 'no'} onChange={(e) => setLateSubmisstion(e.target.value)} />
                                        <div className="form-check-label fw-bold">No</div>
                                    </label>
                                </div>
                            </form>
                        </div>

                        <div className="tab-pane fade" id="groupSectionBox" role="tabpanel" tabIndex="0">
                            <form action="" className="needs-validation" id="assignGroupToAssignmentForm" noValidate>
                                {groupSectionList && groupSectionList.length > 0 ?
                                    <div className="row row-cols-1 row-cols-md-4 g-4">
                                        {groupSectionList.map((item, i) => {
                                            return (
                                                <div className="col" key={i}>
                                                    <label className="label-cardwrap">
                                                        <input type="checkbox" value={item.id} name="group_section[]" id={`group_section_${item.id}`} defaultChecked={assignmentDetails && assignmentDetails.assignment_section.includes(item.id)} />
                                                        <div className="card rounded-3 p-4">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="pe-3">
                                                                    <h4 className="fw-bold">{item.section_name}</h4>
                                                                </div>
                                                                <div className="clone-checkbox rounded-2 mmwh-24">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : <div className="row mt-1"><h4 className="fw-bold text-danger text-center">Section Not Found</h4></div>}
                            </form>
                        </div>

                        <div className="tab-pane fade" id="workbookBox" role="tabpanel" tabIndex="0">
                            <div className="row g-3">
                                <div className="col-lg-4 pe-lg-5">
                                    <form id="filterForm">
                                        <div className="aside-filter" id="asideFilterBox">
                                            <div className="aside-innerfilter">
                                                <div className="d-flex d-lg-none align-items-center justify-content-between pb-3">
                                                    <strong className="fs-17">Filter By</strong>
                                                    <button type="button" className="btn" onClick={() => filterToggle()} title="Close"><svg className="icon ts-1p5"><use href="#icon_clear"></use></svg></button>
                                                </div>

                                                {filterItem && filterItem.module && filterItem.module.length > 0 && <WorkbookFilter filter={setFilter} module={filterItem.module} />}

                                                {filterItem && filterItem.question_type && filterItem.question_type.length > 0 &&
                                                    <>
                                                        <h5 className="fs-18 text-uppercase fw-bold text-blue mt-5 mb-0">Question Type</h5>
                                                        {filterItem.question_type.map((item, key) => <FilterTopic key={key} name="question_type[]" value={item.value} label={item.type} fun={() => setFilter(now)} />)}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="col-lg-8 body-over_flowx pse-10px">
                                    <div className="d-lg-flex justify-content-between">
                                        <div className="d-flex justify-content-between mb-4 mb-md-3 order-1">
                                            <button type="button" className="btn btn-link d-lg-none" onClick={() => filterToggle()}>
                                                <svg className="icon ts-1p4"><use href="#icon_filter"></use></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <form id="assignWorkbookToAssignmentForm" noValidate>
                                        {data && data.data && data.data.length > 0 && data.data.map((item) => {
                                            return (
                                                <div className="col-lg-12 mb-4" key={item.workbook.id}>
                                                    <label className="label-cardwrap">
                                                        <input type="checkbox" value={item.workbook.id} name="workbook_id[]" id={`workbook_id_${item.workbook.id}`} onChange={(e) => handleCheckboxChange(e)} defaultChecked={workbookIds.includes(item.workbook.id.toString())} />
                                                        <div className="card rounded-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="card-body p-md-3 d-sm-flex align-items-center">
                                                                    <div className="mmwh-56 d-sm-block shadow-md rounded-3 position-relative overflow-hidden me-3 me-md-4 l">
                                                                        <img src={item.image && ASSET_ENDPOINT + item.image} className="h-100 ratio hm-180" alt="Workbook" onError={noImageHandle} />
                                                                    </div>
                                                                    <div className="d-block mt-3 mt-sm-0 w-100">
                                                                        <h5 className="fw-bold workbook-heading-smallsize ">
                                                                            {item.workbook && item.workbook.title}
                                                                            <span className="text-white fw-bold rounded-pill px-2 minw-70 py-1 ms-2 mt-2 text-center fs-18" style={{ background: `hsl(${item.course && item.course.accent_color}, 100%, 50%)` }}>{item.course && item.course.course_abbr}</span>
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </form>

                                    {data && data.data && data.data.length === 0 && <div className="row mt-1"><h4 className="fw-bold my-3 text-danger">Workbook Not Found</h4></div>}

                                    {data && data.total ? <PageNavigation active_page={data.current_page - 1} per_page={data.per_page} filter_recoards={data.total} setCurrentPage={setCurrentPage} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAssignment