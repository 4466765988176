import { useEffect, useState } from "react";
import { fetchData, PAGE_CONTENT } from "../../components/Service"
import GetStarted from "./partials/GetStarted";
import AboutAuthor from "./partials/AboutAuthor";
import StudentTestimonials from "./partials/StudentTestimonials";
import Workbook from "./partials/Workbook";
import StudyMode from "./partials/StudyMode";
// import QuestionModel from "./partials/QuestionModel";
import HomeBanner from "./partials/HomeBanner";
import QuestionSolution from "../../components/QuestionSolution"
import DragAndFill from "../Exam/partials/QuestionType/DragAndFill";
import { now } from "lodash";
import { Context } from '../../components/Context';
import { useContext } from "react";
import { showAlertMsg } from "../../components/CommonMethod";

const Home = () => {
    window.document.title = "Home";

    const [homeContent, setHomeContent] = useState('')
    const [progressData, setProgressData] = useState({index: 0, value: '', correctText: ['Well Done!'], incorrectText: ['Not quite..']})
    const [aboutContent, setAboutContent] = useState('')
    const [question, setQuestion] = useState('')
    const [refresh, setRefresh] = useState(now)
    const [context] = useContext(Context)

    useEffect(() => {
        let block_user = localStorage.getItem('block_user')
        if(block_user) {
            localStorage.removeItem('block_user')
            showAlertMsg(JSON.parse(block_user))
        }
    }, [])

    useEffect(() => {
        fetchData(`${PAGE_CONTENT}?type=home-content`, 'GET', '', false, false, (res) => {
            if (res.records) {
                setHomeContent(JSON.parse(res.records))
                let question = JSON.parse(res.records).question
                if(question) setQuestion(question)
            }
        });

        fetchData(`${PAGE_CONTENT}?type=workbook-content`, 'GET', '', false, false, (res) => {
            if (res.records) {
                let progressText = JSON.parse(res.records);
                setProgressData({correctText: progressText.correct_answer, incorrectText: progressText.incorrect_answer, index: 1, value: false})
            }
        });

        fetchData(`${PAGE_CONTENT}?type=about-content`, 'GET', '', false, false, (res) => {
            if (res.records) {
                setAboutContent(JSON.parse(res.records))
            }
        });
    },[refresh])

    useEffect(() => {
        setQuestion('')
        setRefresh(now)
    }, [context])

    const tryAgain = () => {
        setQuestion('')
        setRefresh(now)
    }
    return(
        <>
            <HomeBanner homeContent={homeContent}/>
            {/* <QuestionModel homeContent={homeContent} workbookContent={workbookContent} /> */}
            { question && <section className="home-section2 bg-white pb-5" id="respon-sec">
                <div className="container container-1040 position-relative pb-5">
                    <div className="row g-4 justify-content-center">
                        <div className="col-sm-12 col-md-10 col-lg-9">
                            <div className="d-flex align-items-start">
                                <svg className="icon mmwh-35 sm-mmwh-28 text-sky">
                                    <use href="#icon_subtract"></use>
                                </svg>
                                <div className="px-3 maxw-420">
                                    <h2 className="fw-bold mb-5 ">Self-driven learning has never been so easy</h2>
                                </div>
                            </div>
                            <div className="query-shape">
                                <div className="shape1"></div>
                                <div className="shape2"></div>
                                <div className="card card-dragging overflow-hidden p-0 border-0" id="question_card">
                                    <div className="card-body border-0 p-0">
                                        <DragAndFill 
                                            serialNo={1} 
                                            mode={'practice'} 
                                            question={question} 
                                            progressData={progressData}
                                            setActiveQuestion={() => {}} 
                                            setPrevAnswerDetails={() => {}} 
                                            setPrevQuestionDetails={() => {}} 
                                            saveAnswer={() => {}} 
                                            setProgress={() => {}} 
                                            tryAgain={tryAgain}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> }
            <StudyMode/>
            <Workbook/>
            <StudentTestimonials homeContent={homeContent}  />
            <AboutAuthor aboutContent={aboutContent} />
            <GetStarted/>
            <QuestionSolution image={homeContent && homeContent.questions && homeContent.questions.solution_image}/>
        </>
    )
}

export default Home