import { ASSET_ENDPOINT } from "../../../components/Service"
import { noImageHandle } from "../../../components/CommonMethod";
import { useContext } from "react";
import { Context } from "../../../components/Context";
import { Link } from "react-router-dom";
const StudentTestimonials = (props) => {
    const [context] = useContext(Context)
    return(
        <section className="home-section5">
            {/* <img src="images/vector/shape3.svg" className="homebg-cover" alt="shape"  type="image/svg+xml"/> */}
            <object type="image/svg+xml" className="homebg-cover" data="images/vector/shape3.svg">
</object>
            <div className="container container-1040 position-relative">
                <div className="row">
                    <div className="col-sm-12 col-lg-7">
                        <div className="d-flex align-items-start justify-content-center">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-lime">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold mb-3 f-28_size">What students say about our workbooks</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    { props.homeContent && props.homeContent.testimonials && props.homeContent.testimonials.length > 0 && props.homeContent.testimonials.map((ele, num) =>{
                        return(
                            <div className={`col-sm-12 ${ num === 0 ? 'mt-4' : 'mt-5 pt-4'}`} key={num}>
                                <div className={num === 0 ? 'card-limebar' : 'card-skybar ms-auto'}>
                                    <div className="card-innerbar">
                                        { num === 0 && <img src={ ele.student_image ? ASSET_ENDPOINT + ele.student_image : 'images/fly.png' } onError={noImageHandle} alt="fly"/> }
                                        <p>
                                            &#8220; {ele.student_message} &#8221;
                                            <span>- {ele.student_name}</span>
                                        </p>
                                        { num !== 0 && <img src={ ele.student_image ? ASSET_ENDPOINT + ele.student_image : 'images/student.png' } onError={noImageHandle} alt="student"/> }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                    <div className="d-flex align-items-start justify-content-center mt-5">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-blue">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold mb-3">Receive a free workbook today!</h2>
                                {/* <h4 className="fw-medium mb-4">Receive a free workbook when you create an account.</h4> */}
                                <div className="d-flex justify-content-center">
                                {context && context.auth ?
                                    <Link to={`${context.auth.user_type === '2' ? '/my-workbooks' : '/cms'}`} className="btn btn-theme-primary btn-manage-width btn-lg mt-2">{context.auth.user_type === '2' ? 'My Workbook' : 'Dashboard'}</Link>
                                    : <Link to="/sign-up" className="btn btn-theme-primary btn-manage-width white-space btn-lg w-100 mt-2"> Sign up</Link>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StudentTestimonials