import React, { useEffect, useContext, useState } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { MenuToggle } from "../components/CommonMethod";
import { Context } from '../components/Context';
import { fetchData, GET_AUTH_USER_INFO, LOGOUT } from '../components/Service'
import { loginGuest } from "./GuestHelper";

const Aside = () => {
    const { pathname } = useLocation()
    const [context, setContext] = useContext(Context)
    const [adminToken, setAdminToken] = useState(localStorage.getItem('waae-admin-token'))
    const navigate = useNavigate();

    const handleLogout = () => {
        fetchData(LOGOUT, 'GET', '', true, true, (res) => {
            if (res.success) {
                localStorage.removeItem("waae-token");
                localStorage.removeItem('waae-admin-token')
                loginGuest()
                setContext('')
                navigate(`/cms/`);
            }
        });
    }

    useEffect(() => {
        document.querySelector('body').classList.remove('menu-toggle');
        document.querySelector('body').classList.remove('body-overflowhidden');
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setAdminToken(localStorage.getItem('waae-admin-token'))
    }, [pathname, context]);

    const goToAdmin = () => {
        if (adminToken) {
            localStorage.removeItem('waae-admin-token')
            localStorage.setItem('waae-token', adminToken)
            fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                if (res.records && res.records.access_token) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))
                    navigate('/cms')
                }
            });
        }
    }

    return (
        <>
            <header className="mobile-header">
                <Link to="/">
                    <img src="/images/logo.svg" alt="Logo" />
                </Link>
                <button type="button" className="btn bg-white" onClick={MenuToggle}>
                    <svg className="icon ts-2"><use href="#icon_bars"></use></svg>
                </button>
            </header>
            <aside className="left-aside">
                <div className="left-logobox">
                    <Link to="/">
                        <img src="/images/logo.svg" alt="Logo" />
                    </Link>
                    <button type="button" className="btn align-self-center px-31 py-21" onClick={MenuToggle}>
                        <svg className="icon ts-1p5"><use href="#icon_arrowback"></use></svg>
                    </button>
                </div>
                {context && context.auth && (context.auth.user_type === '5' || context.auth.user_type === '4') &&
                    <div className="card text-center mx-3 bg-gradient1 border-0">
                        <div className="card-header">
                            <span className="text-white fw-bold fs-20">{context.auth.group_details && context.auth.group_details.group_name}</span> <small className="fw-bold text-white">({context.auth.group_details && context.auth.group_details.group_code})</small>
                        </div>
                        <div className="card-body">
                            <div className="text-white fw-bold fs-20">{context.auth.first_name && context.auth.first_name} {context.auth.last_name && context.auth.last_name}</div>
                            <h6 className="text-white">{context.auth.email && context.auth.email}</h6>
                        </div>
                    </div>
                }

                <div className="left-menulist" id="accordionBox">
                    {context && context.auth && (context.auth.user_type === '1' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('dashboard'))) &&
                        <NavLink to="dashboard" className={`main-link ${['/cms', '/cms/dashboard'].includes(pathname) ? 'active' : ''}`}>
                            <svg className="icon"><use href="#icon_dashboard"></use></svg>
                            <span>Dashboard</span>
                        </NavLink>
                    }

                    {context && context.auth && (context.auth.user_type === '1' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('analytics'))) &&
                        <NavLink to="analytics" className="main-link mt-2">
                            <svg className="icon ts-1p1"><use href="#icon_analytics"></use></svg>
                            <span>Analytics</span>
                        </NavLink>
                    }

                    {context && context.auth && (context.auth.user_type === '1' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('currency'))) &&
                        <NavLink to="currency" className="main-link mt-2">
                            <svg className="icon ts-1p2"><use href="#icon_dollar"></use></svg>
                            <span>Currency</span>
                        </NavLink>
                    }

                    {context && context.auth && (context.auth.user_type !== '2') && <>
                        <div className="more-menu">
                            <NavLink to="users" className={`main-link mt-2 ${['/cms/users', '/cms/create-user', '/cms/update-user', '/cms/user-assignment', '/cms/users-analytics'].includes(pathname) ? 'active' : ''}`}>
                                <svg className="icon"><use href="#icon_users"></use></svg>
                                <span>Users</span>
                            </NavLink>
                            {context.auth.user_type !== '5' && context.auth.user_type !== '4' &&
                                <button type="button" data-bs-toggle="collapse" data-bs-target="#usersBox" className={['/cms/groups', '/cms/create-group', '/cms/manage-group', '/cms/update-group'].includes(pathname) ? '' : 'collapsed'} aria-expanded={['/cms/groups', '/cms/create-group', '/cms/manage-group', '/cms/update-group'].includes(pathname) ? true : false}>
                                    <svg className="icon"><use href="#icon_caretdown"></use></svg>
                                </button>
                            }
                        </div>
                        {context.auth.user_type !== '5' && context.auth.user_type !== '4' &&
                            <div className={`collapse ${['/cms/groups', '/cms/create-group', '/cms/manage-group', '/cms/update-group'].includes(pathname) ? 'show' : ''}`} id="usersBox" data-bs-parent="#accordionBox">
                                <NavLink to="groups" className={`sub-link mt-2 ${['/cms/groups', '/cms/create-group', '/cms/manage-group', '/cms/update-group'].includes(pathname) ? 'active' : ''}`}>Groups</NavLink>
                            </div>
                        }
                    </>}

                    {context && context.auth && (context.auth.user_type === '4' || context.auth.user_type === '5') &&
                        <NavLink to="assigned-workbook" className={`main-link mt-2 ${['/cms/assigned-workbook'].includes(pathname) ? 'active' : ''}`}>
                            <svg className="icon"><use href="#icon_noteadd"></use></svg>
                            <span>Workbooks</span>
                        </NavLink>
                    }

                    {context && context.auth && context.auth.user_type === '5' &&
                        < NavLink to="group-section" className={`main-link mt-2 ${['/cms/group-section', '/cms/create-group-section', '/cms/update-group-section'].includes(pathname) ? 'active' : ''}`}>
                            <svg className="icon"><use href="#icon_noteadd"></use></svg>
                            <span>Group sections</span>
                        </NavLink>
                    }

                    {context && context.auth && context.auth.user_type === '4' &&
                        < NavLink to="assignments" className={`main-link mt-2 ${['/cms/assignments', '/cms/create-assignment', '/cms/update-assignment', '/cms/assignment-analytics'].includes(pathname) ? 'active' : ''}`}>
                            <svg className="icon"><use href="#icon_noteadd"></use></svg>
                            <span>Assignments</span>
                        </NavLink>
                    }

                    {context && context.auth && (context.auth.user_type === '1' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('workbooks'))) &&
                        <NavLink to="manage-workbooks" className={`main-link mt-2 ${['/cms/manage-workbooks', '/cms/create-workbook', '/cms/update-workbook'].includes(pathname) ? 'active' : ''}`}>
                            <svg className="icon"><use href="#icon_noteadd"></use></svg>
                            <span>Workbooks</span>
                        </NavLink>
                    }

                    {context && context.auth && (context.auth.user_type === '1' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('listings'))) && <>
                        <div className="more-menu">
                            <NavLink to="listings" className={`main-link mt-2 ${['/cms/listings', '/cms/create-listing', '/cms/update-listing'].includes(pathname) ? 'active' : ''}`}>
                                <svg className="icon ts-1p1"><use href="#icon_publish"></use></svg>
                                <span>Listings</span>
                            </NavLink>
                            <button type="button" data-bs-toggle="collapse" data-bs-target="#listingsBox" className={['/cms/manage-courses', '/cms/create-course', '/cms/update-course', '/cms/topic', '/cms/section'].includes(pathname) ? '' : 'collapsed'} aria-expanded={['/cms/manage-courses', '/cms/create-course', '/cms/update-course', '/cms/topic', '/cms/section'].includes(pathname) ? true : false}>
                                <svg className="icon"><use href="#icon_caretdown"></use></svg>
                            </button>
                        </div>

                        <div className={`collapse ${['/cms/manage-courses', '/cms/create-course', '/cms/update-course', '/cms/topic', '/cms/section'].includes(pathname) ? 'show' : ''}`} id="listingsBox" data-bs-parent="#accordionBox">
                            <NavLink to="manage-courses" className={`sub-link mt-2 ${['/cms/manage-courses', '/cms/create-course', '/cms/update-course'].includes(pathname) ? 'active' : ''}`}>Courses</NavLink>
                            <NavLink to="topic" className="sub-link mt-2">Topic</NavLink>
                            <NavLink to="section" className="sub-link mt-2">Section</NavLink>
                        </div>
                    </>}

                    {context && context.auth && (context.auth.user_type === '1' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('website-content'))) && <>
                        <div className="more-menu">
                            <NavLink to="website-content" className="main-link mt-2">
                                <svg className="icon"><use href="#icon_image"></use></svg>
                                <span>Website content</span>
                            </NavLink>
                            <button type="button" data-bs-toggle="collapse" data-bs-target="#wcBox" className={['/cms/home-page', '/cms/about-us-page', '/cms/social-media', '/cms/shop-page', '/cms/checkout-page', '/cms/workbook-page', '/cms/wc-terms-and-conditions', '/cms/file-manager'].includes(pathname) ? '' : 'collapsed'} aria-expanded={['/cms/home-page', '/cms/about-us-page', '/cms/social-media', '/cms/shop-page', '/cms/checkout-page', '/cms/workbook-page', '/cms/wc-terms-and-conditions', '/cms/file-manager'].includes(pathname) ? true : false}>
                                <svg className="icon"><use href="#icon_caretdown"></use></svg>
                            </button>
                        </div>
                        <div className={`collapse ${['/cms/home-page', '/cms/about-us-page', '/cms/social-media', '/cms/shop-page', '/cms/checkout-page', '/cms/workbook-page', '/cms/wc-terms-and-conditions', '/cms/file-manager'].includes(pathname) ? 'show' : ''}`} id="wcBox" data-bs-parent="#accordionBox">
                            <NavLink to="home-page" className="sub-link mt-2">Homepage</NavLink>
                            <NavLink to="about-us-page" className="sub-link mt-2">About us</NavLink>
                            <NavLink to="social-media" className="sub-link mt-2">Social media</NavLink>
                            <NavLink to="shop-page" className="sub-link mt-2">Shop</NavLink>
                            <NavLink to="checkout-page" className="sub-link mt-2">Checkout</NavLink>
                            <NavLink to="workbook-page" className="sub-link mt-2">Workbooks section</NavLink>
                            <NavLink to="wc-terms-and-conditions" className="sub-link mt-2">Terms and conditions</NavLink>
                            <NavLink to="file-manager" className="sub-link mt-2">File Manager</NavLink>
                        </div>
                    </>}

                    {context && context.auth && (context.auth.user_type === '1' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('customer-enquiries'))) &&
                        <NavLink to="customer-enquiries" className="main-link mt-2">
                            <svg className="icon"><use href="#icon_support"></use></svg>
                            <span>Customer enquiries</span>
                        </NavLink>
                    }

                    {context && context.auth && context.auth.user_type === '1' &&
                        <NavLink to="settings" className="main-link mt-2">
                            <svg className="icon"><use href="#icon_setting"></use></svg>
                            <span>Settings</span>
                        </NavLink>
                    }

                    {context && context.auth && (context.auth.user_type === '1' || context.auth.user_type === '3' || context.auth.user_type === '4' || context.auth.user_type === '5') &&
                        <div className="more-menu">
                            <NavLink to="profile" className="main-link mt-2">
                                <svg className="icon"><use href="#icon_dashboard"></use></svg>
                                <span>My account</span>
                            </NavLink>
                            <button type="button" data-bs-toggle="collapse" data-bs-target="#accountBox" className={['/cms/profile', '/cms/change-password'].includes(pathname) ? '' : 'collapsed'} aria-expanded={['/cms/profile', '/cms/change-password'].includes(pathname) ? true : false}>
                                <svg className="icon"><use href="#icon_caretdown"></use></svg>
                            </button>
                        </div>
                    }
                    <div className={`collapse ${['/cms/profile', '/cms/change-password'].includes(pathname) ? 'show' : ''}`} id="accountBox" data-bs-parent="#accordionBox">
                        <NavLink to="profile" className="sub-link mt-2">Profile</NavLink>
                        <NavLink to="change-password" className="sub-link mt-2">Change password</NavLink>
                    </div>

                </div>
                <div className="p-20">
                    {adminToken &&
                        <div className="main-link" role="button" onClick={() => goToAdmin()}>
                            <svg className="icon"><use href="#icon_login"></use></svg>
                            <span>Go to admin</span>
                        </div>
                    }
                    <Link to="#" className="main-link" onClick={() => handleLogout()}>
                        <svg className="icon"><use href="#icon_logout2"></use></svg>
                        <span>Sign out</span>
                    </Link>
                </div>
            </aside>
        </>
    )
}

export default Aside;