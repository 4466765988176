import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateForm, initialFormState, ButtonSpinner, copyToClipboard, gentPassword, toastNotify, ConfirmationModal, PasswordShow, noImageHandle } from "../../components/CommonMethod";
import { fetchData, USER_CREATE, UPDATE_STUDENT_INFO, DROPDOWN_DATA, GET_GROUP_WORKBOOKS, ASSET_ENDPOINT, USER_WORKBOOK_ASSIGN, USER_WORKBOOK_LIST, USER_WORKBOOK_UNASSIGN } from '../../components/Service';
import AssignWorkbookModal from '../../components/AssignWorkbookModal'
import { now } from 'lodash'
import { Context } from "../../components/Context";
import { PageNavigation } from "../../components/DataTable";

const CreateUser = () => {
    const location = useLocation();
    let userInfo = location.state
    let title = userInfo ? "Update User" : "Create User"

    window.document.title = title

    const navigate = useNavigate();
    const [context] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);
    const [assignWorkbook, setAssignWorkbook] = useState(false)
    const [dropdownData, setdropdownData] = useState('');
    const [yearLevel, setYearLevel] = useState('');
    const [courceId, setCourceId] = useState('');
    const [groupSection, setGroupSection] = useState('');
    const [schoolCode, setSchoolCode] = useState('');
    const [checkAgeValue, setcheckAgeValue] = useState(0);
    const [workbookData, setWorkbookData] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [deleteSections, setDeleteSections] = useState({ id: '', title: '' });
    const [currentPage, setCurrentPage] = useState(1)

    var user_group = userInfo && userInfo.school_group_code ? userInfo.school_group_code : '';

    const createUser = (e) => {
        let formdata = new FormData(document.getElementById('createUserForm'));
        formdata.append('age_group', checkAgeValue)

        if (validateForm(e, 'createUserForm')) {
            setTinyloader(true);

            fetchData(userInfo ? UPDATE_STUDENT_INFO : USER_CREATE, 'POST', formdata, userInfo ? true : false, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    initialFormState('createUserForm')
                    navigate('../users')
                }
            });
        }
    }
    const checkGroupCode = (e) => {
        if (e.target.checked) {
            document.getElementById('group_code_div').style.display = "block"
            document.getElementById('school_group_code').required = true;
        } else {
            document.getElementById('group_code_div').style.display = "none"
            document.getElementById('school_group_code').required = false;
            document.getElementById('school_group_code').value = "";
        }
    }

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=all&section_group_id=' + (context && context.auth && context.auth.group_details && context.auth.group_details.group_id), 'GET', '', false, false, (res) => {
            if (res.records) {
                setdropdownData(res.records)
            }
        })

        if (userInfo !== null) {
            console.log(userInfo)
            document.getElementById('email').required = false;
            document.getElementById('school_group_code').required = false;
            document.getElementById('required_attr').innerText = '';
            setYearLevel(userInfo && userInfo.year_level);
            setCourceId(userInfo && userInfo.course_id);
            setGroupSection(userInfo && userInfo.group_section);
        }

        if (context && context.auth && context.auth.user_type !== '4') {
            if ((userInfo && userInfo.school_group_code !== "") || (context && context.auth && context.auth.group_details !== '')) {
                document.getElementById('group_code_div').style.display = "block";
                document.getElementById('select_group').checked = true;
                setSchoolCode(context && context.auth && context.auth.group_details ? context && context.auth && context.auth.group_details.group_code : userInfo && userInfo.school_group_code);
            }
        }

        if (userInfo && userInfo.age_group === 1) {
            document.getElementById('age_group').checked = true;
        }

        if (userInfo && userInfo.p_first_name) {
            document.getElementById('guardian_details_div').style.display = "block";
        } else {
            document.getElementById('guardian_details_div').style.display = "none";
        }

        setcheckAgeValue(userInfo && userInfo.age_group);

    }, [userInfo, refresh, context])

    useEffect(() => {
        var id = user_group ? user_group : userInfo && userInfo.id;
        var api = user_group ? GET_GROUP_WORKBOOKS : USER_WORKBOOK_LIST;

        if (id) {
            fetchData(`${api}/${id}?page=${currentPage}`, 'GET', '', true, false, (res) => {
                if (res.records) {
                    setWorkbookData(res.records)
                } else {
                    toastNotify('danger', res.error)
                }
            })
        }

    }, [userInfo, refresh, currentPage, user_group])

    const unAssignWorkbook = (id) => {

        fetchData(USER_WORKBOOK_UNASSIGN, 'DELETE', { 'list_id': id, 'user_id': userInfo.id }, true, false, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now)
                setCurrentPage(1)
                setAssignWorkbook(now)
            }
        })
    }

    return (<>
        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row gx-4 mb-1 align-items-center">
                    <div className="col-lg-6 col-6 mb-3">
                        <h1 className="h3 text-dim mb-0">{title}</h1>
                    </div>
                    <div className="col-lg-6 col-6 mb-3">
                        <button type="button" className="btn btn-theme-primary btn-lg px-3 float-end" onClick={(e) => createUser(e)} disabled={tinyloader}>
                            <ButtonSpinner load={tinyloader} btnName="Save and Exit" />
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <form className="needs-validation" method="post" id="createUserForm" noValidate autoComplete="off">
                    <div className="col-sm-12">
                        <div className="maxw-600">
                            <h5 className="line-heading text-blue py-2"><span>Student details</span></h5>
                            <div className="row g-4">
                                <div className="col-sm-6 pt-2">
                                    <div className="form-floating">
                                        <input type="hidden" name="student_id" defaultValue={userInfo && userInfo.id} />
                                        <input type="text" name="first_name" id="first_name" className="form-control form-control-md" placeholder="First name" defaultValue={userInfo && userInfo.first_name} required />
                                        <div className="invalid-feedback">
                                            The first name field is required.
                                        </div>
                                        <label>First name <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-sm-6 pt-2">
                                    <div className="form-floating">
                                        <input type="text" name="last_name" id="last_name" className="form-control form-control-md" placeholder="Last name" defaultValue={userInfo && userInfo.last_name} required />
                                        <div className="invalid-feedback">
                                            The last name field is required.
                                        </div>
                                        <label>Last name <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6 pt-2">
                                    <div className="form-floating">
                                        <select value={courceId} onChange={(e) => setCourceId(e.target.value)} className="form-select form-select-md" name="course_type" id="course_type" required>

                                            {dropdownData && dropdownData.courses && dropdownData.courses.length > 0 ? dropdownData.courses.map((item, i) => {
                                                return (
                                                    <option value={item.id} key={i}>{item.course_abbr} ({item.name})</option>
                                                )
                                            }) : <option value="">Course Type</option>}
                                        </select>
                                        <div className="invalid-feedback">
                                            The course type field is required.
                                        </div>
                                        <label>Course type <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6  pt-2">
                                    <div className="form-floating">
                                        <select value={yearLevel} onChange={(e) => setYearLevel(e.target.value)} className="form-select form-select-md" name="year_level" id="year_level" required>
                                            {dropdownData && dropdownData.levels && dropdownData.levels.length > 0 ? dropdownData.levels.map((item, i) => {
                                                return (
                                                    <option value={item.id} key={i}>{item.name}</option>
                                                )
                                            }) : <option value="">Year level </option>}
                                        </select>
                                        <div className="invalid-feedback">
                                            The year level field is required.
                                        </div>
                                        <label>Year level <span className="text-danger">*</span></label>
                                    </div>
                                </div>

                                {context && context.auth && context.auth.user_type === '4' &&
                                    <div className="col-md-6 pt-2">
                                        <div className="form-floating">
                                            <select value={groupSection} onChange={(e) => setGroupSection(e.target.value)} className="form-select form-select-md" name="group_section" id="group_section" required>
                                                {dropdownData && dropdownData.group_sections && dropdownData.group_sections.length > 0 ? dropdownData.group_sections.map((item, i) => {
                                                    return (
                                                        <option value={item.id} key={i}>{item.section_name}</option>
                                                    )
                                                }) : <option value="">Section</option>}
                                            </select>
                                            <div className="invalid-feedback">
                                                The section field is required.
                                            </div>
                                            <label>Section <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                }
                            </div>
                            {context && context.auth && context.auth.user_type !== '4' ?
                                <div className="py-3 mt-1">
                                    <label className="d-inline-block d-flex mb-4">
                                        <input className="form-check-input" name="group_check" id="select_group" type="checkbox" value="" onClick={(e) => checkGroupCode(e)} disabled={context && context.auth && context.auth.group_details ? true : false} />
                                        <div className="form-check-label fw-bold"><span className="ms-2">I have a school group code to sign up with</span></div>
                                    </label>
                                    <div className="form-floating mt-25 maxw-200" id="group_code_div" style={{ display: "none" }}>
                                        <select value={schoolCode} onChange={(e) => setSchoolCode(e.target.value)} className="form-select form-select-md" name="school_group_code" id="school_group_code">
                                            <option value="" disabled>Select group code</option>
                                            {dropdownData && dropdownData.groups && dropdownData.groups.length > 0 && dropdownData.groups.map((item1, i) => {
                                                return (
                                                    <option value={item1.group_code} key={i} >{item1.group_code}</option>
                                                )
                                            })}
                                        </select>
                                        <div className="invalid-feedback" id="grp_response">
                                            The group code field is required.
                                        </div>
                                        <label>Group code <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                :
                                <input type="hidden" className="form-check-input" name="school_group_code" id="school_group_code" value={context && context.auth && context.auth.group_details && context.auth.group_details.group_code} />
                            }

                            <h5 className="line-heading text-blue pt-4"><span>Account details</span></h5>
                            <p>
                                You’ll be able to access your workbooks later by using these details.
                            </p>
                            <div className="row g-3">
                                <div className="col-lg-6 pt-2">
                                    <div className="form-floating">
                                        <input type="email" name="email" id="email" className="form-control form-control-md" placeholder="Email address" defaultValue={userInfo && userInfo.email} required autoComplete="new-password" />
                                        <div className="invalid-feedback">
                                            Email is invalid.
                                        </div>
                                        <label>Email address <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-lg-1 pt-2">
                                    <button type="button" className="btn btn-outline-default" onClick={() => copyToClipboard('email')}>
                                        Copy
                                    </button>
                                </div>
                                <div className="col-sm-12 m-0"></div>
                                <div className="col-lg-6 pt-2">
                                    <div className="form-floating password-wrapper">
                                        <input type="text" name="password" placeholder="Password" id="password" className="form-control form-control-md" defaultValue="" required={userInfo && userInfo.id ? false : true} autoComplete="off" />
                                        <div className="invalid-feedback position-absolute">
                                            The password field is required.
                                        </div>
                                        <div>
                                            <button type="button" className="btn eye-btn" onClick={(e) => PasswordShow(e, 'password')}>
                                                <svg className="icon pe-none"><use href="#icon_eyeoff"></use></svg>
                                            </button>
                                        </div>
                                        <label>Password <span className="text-danger" id="required_attr">*</span></label>

                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1  col-1 pt-2 tec_btn-left">
                                    <button type="button" className="btn btn-outline-default" onClick={() => copyToClipboard('password')}>Copy</button>
                                </div>

                                <div className="col-lg-4 col-md-10 col-9 pt-2 ms-4 small_dev-end" >
                                    <button type="button" className="btn btn-theme-primary" onClick={() => gentPassword('password')}>Generate Password</button>
                                </div>
                                <div className="col-lg-8 text-secondary">Password should be minimum 8 characters!</div>
                            </div>
                            <div className="py-3 mt-1">
                                <label className="d-inline-block d-flex">
                                    <input className="form-check-input" defaultValue={checkAgeValue} type="checkbox" id="age_group" onChange={(e) => setcheckAgeValue(e.target.checked ? 1 : 0)} disabled={userInfo && userInfo.p_first_name ? true : false} />
                                    <div className="form-check-label fw-bold"><span className="ms-2">Is the user under 18 years old?</span></div>
                                </label>
                                {/* start Guardian’s Details Div */}
                                <div className="py-3" id="guardian_details_div" style={{ display: 'none' }}>
                                    <h5 className="line-heading text-blue py-2"><span>Guardian Details</span></h5>
                                    <div className="row g-4 pt-1">
                                        <div className="col-md-6 pt-1">
                                            <div className="form-floating">
                                                <input type="text" name="guardian_first_name" id="guardian_first_name" className="form-control form-control-md" defaultValue={userInfo && userInfo.p_first_name} disabled />
                                                <div className="invalid-feedback">
                                                    The Guardian’s first field is required.
                                                </div>
                                                <label>Guardian’s first name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pt-1">
                                            <div className="form-floating">
                                                <input type="text" name="guardian_last_name" id="guardian_last_name" className="form-control form-control-md" defaultValue={userInfo && userInfo.p_last_name} disabled />
                                                <div className="invalid-feedback">
                                                    The Guardian’s last field is required.
                                                </div>
                                                <label>Guardian’s last name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pt-1">
                                            <div className="form-floating">
                                                <input type="text" name="guardian_relationship" id="guardian_relationship" className="form-control form-control-md text-capitalize" defaultValue={userInfo && userInfo.relation_ship} disabled />
                                                <div className="invalid-feedback">
                                                    The Relationship with student field is required.
                                                </div>
                                                <label>Relationship with student</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end Guardian’s Details Div */}
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/* Workbooks Section */}
            {userInfo !== null &&
                <>
                    <div className="row gx-4 mt-4 align-items-end">
                        <div className="col-sm mb-3">
                            {(!user_group || (workbookData && workbookData.data && workbookData.data.length)) ? <h4 className="mb-0 fw-bold">Workbooks</h4> : ''}
                        </div>
                        <div className="col-auto mb-3">
                            {!user_group && <span role="button" className="btn btn-green px-3 btn-lg" data-bs-toggle="modal" data-bs-target="#addWorkbooks_modal">+ Assign workbooks</span>}
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-xl-2 g-4">
                        {workbookData && workbookData.data && workbookData.data.length >= 1 &&
                            workbookData.data.map((ele, num) => {
                                return (
                                    <div className="col" key={num}>
                                        <div className="card border-blue-hover rounded-3">
                                            <div className="card-body p-md-4 d-sm-flex">
                                                <div className="mmwh-160 shadow-md rounded-3 position-relative overflow-hidden me-3 me-md-4">
                                                    <img src={ele.image ? ASSET_ENDPOINT + ele.image : '../images/ionic-purple.jpg'} onError={noImageHandle} className="h-100 ratio" alt="" />
                                                    <span className="position-absolute start-0 top-0 text-white fw-bold rounded-pill px-2 minw-70 py-1 ms-2 mt-2 text-center" style={{ background: `hsl(${ele.accent_color}, 100%, 50%)` }}>{ele.course_abbr}</span>
                                                </div>
                                                <div className="d-block mt-3 mt-sm-0">
                                                    <h3 className="fw-bold">{ele.title}</h3>
                                                    <p className="fw-bold">Total Workbooks: {ele.total_workbook}</p>
                                                </div>
                                                {!user_group &&
                                                    <div className="ms-auto">
                                                        <button type="button" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={(e) => setDeleteSections({ id: ele.id, title: ele.title })} className="btn btn-outline-danger border-dark mmwh-48">
                                                            <svg className="icon ts-1p5"><use href="#icon_delete"></use></svg>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {workbookData && workbookData.total ? <PageNavigation active_page={workbookData.current_page - 1} per_page={workbookData.per_page} filter_recoards={workbookData.total} setCurrentPage={setCurrentPage} /> : ''}
                    </div>
                </>
            }
        </div>
        {!user_group && <AssignWorkbookModal api={USER_WORKBOOK_ASSIGN} student_id={userInfo && userInfo.id} setRefresh={setRefresh} assignWorkbook={assignWorkbook} />}
        <ConfirmationModal msg={`Delete ${deleteSections.title}`} method={() => unAssignWorkbook(deleteSections.id)} />

    </>)
}
export default CreateUser;
