import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from '../../components/Context';
import { __echoText, toastNotify, ButtonSpinner, validateForm, initialFormState, getCountryFlagSrc, PasswordShow, ConfirmationModal } from "../../components/CommonMethod";
import { DataTable } from "../../components/DataTable";
import { fetchData, USER_LIST, USER_STATUS_CHANGE, USER_DELETE, USER_ASSIGN_GROUP, GROUP_LIST, DROPDOWN_DATA, UPDATE_PARTIAL_ADMIN, SET_PERMISSION, DELETE_TEACHER, EMAIL_VERIFY, GROUP_SECTION_LIST, USER_ASSIGN_GROUP_SECTION, DIRECT_LOGIN } from "../../components/Service";
import { format } from 'date-fns';
import { now } from 'lodash';

const Users = () => {
    window.document.title = "Manage Users";

    const [context, setContext] = useContext(Context)
    const [refreshStudent, setRefreshStudent] = useState(true);
    const [refreshGroupAdmin, setRefreshGroupAdmin] = useState(false);
    const [refreshPartialAdmin, setRefreshPartialAdmin] = useState(false);
    const [refreshTeacher, setRefreshTeacher] = useState(false);
    const [userType, setUserType] = useState('student');
    const [tinyloader, setTinyloader] = useState(false);
    const [groupsData, setGroupsData] = useState('')
    const [groupSectionData, setGroupSectionData] = useState('')
    const [studentData, setStudentDta] = useState([])
    const [permissionList, setPermissionList] = useState('')
    const [userId, setUserId] = useState('')
    const [partialAdminData, setPartialAdminData] = useState('')
    const [deleteTeacherId, setDeleteTeacherId] = useState(0)
    const navigate = useNavigate()

    let group_code = context && context.auth && context.auth.group_details && context.auth.group_details.group_code;

    const students = {
        id: 'students',
        fetchurl: USER_LIST + '/' + 2 + (group_code ? '/' + group_code : ''),
        columns: [
            { data: 'id', name: 'users.id', title: '', sorting: false, searching: false },
            { data: 'first_name', name: 'users.first_name', title: 'STUDENT NAME' },
            { data: 'email', name: 'users.email', title: 'EMAIL ADDRESS' },
            { data: 'group_name', name: 'groups.group_name', title: 'GROUP' },
            { data: 'section_name', name: 'sections.section_name', title: 'SECTION' },
            { data: 'workbook_count', name: 'workbook_count', title: 'WORKBOOKS', searching: false, sorting: false },
            { data: 'status', name: 'users.status', title: 'STATUS' },
            { data: 'created_at', name: 'users.created_at', title: 'DATE JOINED' },
            { data: 'action', name: 'action', title: 'Action', sorting: false, searching: false, visible: (context && context.auth && context.auth.user_type === "5") ? false : true }
        ]
    }

    const groupAdmins = {
        id: 'groupAdmins',
        fetchurl: USER_LIST + '/' + 5,
        columns: [
            { data: 'id', name: 'users.id', title: 'ID' },
            { data: 'group_name', name: 'groups.group_name', title: 'GROUP NAME' },
            { data: 'school_group_code', name: 'users.school_group_code', title: 'GROUP CODE' },
            { data: 'email', name: 'users.email', title: 'EMAIL' },
            { data: 'user_limit', name: 'groups.user_limit', title: 'USER LIMIT' },
            { data: 'created_at', name: 'users.created_at', title: 'DATE JOINED' }
        ]
    }

    const teachers = {
        id: 'teachers',
        fetchurl: USER_LIST + '/' + 4 + (group_code ? '/' + group_code : ''),
        columns: [
            { data: 'id', name: 'id', title: 'ID' },
            { data: 'first_name', name: 'users.first_name', title: 'NAME' },
            { data: 'email', name: 'users.email', title: 'EMAIL' },
            { data: 'group_name', name: 'groups.group_name', title: 'GROUP NAME' },
            { data: 'status', name: 'users.status', title: 'STATUS', visible: context && context.auth && context.auth.user_type === '4' ? false : true },
            { data: 'created_at', name: 'users.created_at', title: 'DATE JOINED' },
            { data: 'action', name: 'action', title: 'Action', sorting: false, searching: false, visible: context && context.auth && context.auth.user_type === '4' ? false : true }

        ]
    }

    const partialAdmins = {
        id: 'partialAdmins',
        fetchurl: USER_LIST + '/' + 3,
        columns: [
            { data: 'id', name: 'id', title: '', sorting: false, searching: false },
            { data: 'first_name', name: 'users.first_name', title: 'NAME' },
            { data: 'email', name: 'users.email', title: 'EMAIL' },
            { data: 'status', name: 'users.status', title: 'STATUS' },
            { data: 'created_at', name: 'users.created_at', title: 'DATE JOINED' },
            { data: 'action', name: 'action', title: 'Action', sorting: false, searching: false }
        ]
    }

    const updateStatus = (id, refresh) => {
        fetchData(USER_STATUS_CHANGE, 'POST', { student_id: id }, true, false, (res) => {
            refresh(now)
        });
    }

    const handleCheckboxChange = (e) => {
        e.target.checked ? setStudentDta([...studentData, e.target.value]) : setStudentDta(studentData.filter(i => i !== e.target.value));
    }

    const removeUsers = (e) => {
        fetchData(USER_DELETE, 'POST', { student_id: studentData }, true, false, (res) => {
            if (res.success) {
                document.querySelector('#deletegroup_modal [data-bs-dismiss="modal"]').click()
                setStudentDta([])

                userType === 'student' ? setRefreshStudent(now) : setRefreshPartialAdmin(now)
            }

            document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        });
    }

    const assignGroup = (e) => {
        let getSelectedValue = document.querySelector('input[name="group"]:checked');

        if (getSelectedValue == null) {
            toastNotify('danger', 'Please select any group to proceed!')
        }

        let group_code = getSelectedValue.value;

        fetchData(USER_ASSIGN_GROUP, 'POST', { group_code: group_code, student_id: studentData }, true, false, (res) => {
            setTinyloader(false);
            if (res.success) {
                setStudentDta([])
                document.querySelector('#selectgroupmodal [data-bs-dismiss="modal"]').click()
            } else {
                toastNotify('danger', res.error)
            }

            setRefreshStudent(now)
            document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        });
    }

    const assignGroupSection = () => {
        let getSelectedValue = document.querySelector('input[name="group_section"]:checked');

        if (getSelectedValue == null) {
            toastNotify('danger', 'Please select a section to proceed!')
        }

        let section_id = getSelectedValue.value;

        fetchData(USER_ASSIGN_GROUP_SECTION, 'POST', { section_id: section_id, student_id: studentData }, true, false, (res) => {
            setTinyloader(false);
            if (res.success) {
                setStudentDta([])
                document.querySelector('#selectgroupsectionmodal [data-bs-dismiss="modal"]').click()
            } else {
                toastNotify('danger', res.error)
            }

            setRefreshStudent(now)
            document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        });
    }

    const getGroups = (type) => {
        fetchData(GROUP_LIST + '?status=active&&order=' + type, 'GET', '', true, false, (res) => {
            if (res.records) {
                setGroupsData(res.records.data)
            } else {
                toastNotify('danger', res.error)
            }
        });
    }

    const getGroupSection = (type) => {
        fetchData(GROUP_SECTION_LIST + '/' + (context && context.auth && context.auth.group_details && context.auth.group_details.group_id) + '?status=active&order=' + type, 'GET', '', true, false, (res) => {
            if (res.records) {
                setGroupSectionData(res.records)
            } else {
                toastNotify('danger', res.error)
            }
        });
    }

    const getPermission = (id) => {
        setUserId(id)
        fetchData(SET_PERMISSION + '/' + id, 'GET', '', true, false, (res) => {
            if (res.records) {
                var permission = res.records && res.records.permission ? res.records.permission : [];
                document.querySelectorAll('.permission_checkboxes').forEach((el) => el.checked = permission.includes(el.value) ? true : false)
            }
        })
    }

    const editPartialAdmin = (e) => {
        var formdata = new FormData(document.getElementById('updatePartialAdminForm'));

        if (validateForm(e, "updatePartialAdminForm")) {
            setTinyloader(true);

            fetchData(UPDATE_PARTIAL_ADMIN, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);

                if (res.success) {
                    setRefreshPartialAdmin(now)
                    document.querySelector('#updatePartialAdmin [data-bs-dismiss="modal"]').click()
                    initialFormState('updatePartialAdminForm');
                }
            })
        }
    }

    const selectListType = (type, refresh) => {
        setUserType(type)
        setStudentDta([])
        document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        refresh(now)
    }

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=permission', 'GET', '', true, false, (res) => {
            if (res.records) {
                setPermissionList(res.records.permission)
            }
        })
    }, [])

    const updatePermission = () => {
        let formData = new FormData(document.getElementById('userPermissionForm'))
        formData.append('id', userId);
        fetchData(SET_PERMISSION, 'POST', formData, true, true, (res) => {
            if (res.success) {
                document.querySelector('#userPermissionModal [data-bs-dismiss="modal"]').click()
            }
        })
    }

    const deleteTeacher = () => {
        fetchData(DELETE_TEACHER, 'POST', { id: deleteTeacherId }, true, false, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefreshTeacher(now)
            }
        });
    }

    const verifyStatus = (id) => {
        fetchData(EMAIL_VERIFY, 'POST', { id: id }, true, false, (res) => {
            setRefreshStudent(now)
        });
    }
    useEffect(() => {
        var elements = document.querySelectorAll('.nav-link');
        elements.forEach(element => {
            element.addEventListener("click", function (e) {
                localStorage.setItem('activeTab', e.target.dataset.bsTarget);
            });
        });
        var activeTab = localStorage.getItem('activeTab');
        if (activeTab) {
            let tab = document.querySelector(`[data-bs-target="${activeTab}"]`);
            if (tab) tab.click();
        }

    }, [])

    const directLogin = (id) => {
        fetchData(`${DIRECT_LOGIN}?id=${id}`, 'GET', '', true, false, (res) => {
            if (res.records && res.records.access_token) {
                let adminToken = localStorage.getItem('waae-token')
                localStorage.setItem('waae-admin-token', adminToken)
                localStorage.setItem('waae-token', res.records.access_token)
                window.location.reload()
            }
        });
    }
    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row g-2 align-items-center">
                        <div className="col-lg-6 col-sm mb-3 col-6">
                            <h1 className="h3 text-dim">Manage Users</h1>
                        </div>
                        {userType === 'student' && (context && context.auth && context.auth.user_type !== '5') &&
                            <div className="col-lg-6 mb-3 col-6">
                                <Link to="/cms/create-user" role="button" className="btn btn-green btn-lg px-3 float-end">+ Add student</Link>
                            </div>
                        }

                        {context && context.auth && context.auth.user_type === '5' && context.auth.user_type !== '4' && context.auth.group_details && userType === 'teachers' &&
                            <div className="col-lg-6 mb-3 col-6">
                                <Link to="/cms/create-teacher" role="button" className="btn btn-green btn-lg px-3 float-end">+ Add Teacher</Link>
                            </div>
                        }

                    </div>
                </div>

                <div className="row bg-grey2 p-2 rounded mx-0 align-items-center my-3" id="selected_sudents" style={{ display: studentData.length > 0 ? 'flex' : 'none' }} >
                    <div className="col-lg-5 col-4 text-dim fs-18">
                        <strong>{studentData && studentData.length > 0 && studentData.length} users selected</strong>
                    </div>

                    <div className="col-lg-7 col-8 text-end">
                        {context && context.auth && context.auth.user_type !== '4' && context.auth.user_type !== '5' && userType === 'student' &&
                            <button type="button" className="btn btn-link fw-bold text-decoration-none me-sm-4" data-bs-toggle="modal" data-bs-target="#selectgroupmodal" onClick={() => getGroups('ASC')}>
                                <svg className="icon ts-1p5 me-2"><use href="#icon_adduser"></use></svg>
                                Assign to existing group
                            </button>
                        }

                        {context && context.auth && context.auth.user_type === '4' && userType === 'student' &&
                            <button type="button" className="btn btn-link fw-bold text-decoration-none me-sm-4" data-bs-toggle="modal" data-bs-target="#selectgroupsectionmodal" onClick={() => getGroupSection('ASC')}>
                                <svg className="icon ts-1p5 me-2"><use href="#icon_adduser"></use></svg>
                                Assign to existing section
                            </button>
                        }

                        <button type="button" className="btn fw-bold text-decoration-none me-sm-4 text-danger" data-bs-toggle="modal" data-bs-target="#deletegroup_modal">
                            <svg className="icon ts-1p5 me-2"><use href="#icon_delete"></use></svg>
                            Remove selected users
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <nav>
                            <div className="nav nav-tabs nav-bar mb-2 pading-top-ress" id="nav-tab" role="tablist">
                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#studentsBox" type="button" role="tab" aria-selected="false" onClick={() => { selectListType('student', setRefreshStudent); }}>Students</button>

                                {context && context.auth && context.auth.user_type !== '5' && context.auth.user_type !== '4' &&
                                    <button className="nav-link " data-bs-toggle="tab" data-bs-target="#groupadminBox" type="button" role="tab" aria-selected="true" onClick={() => { selectListType('group-admin', setRefreshGroupAdmin); }}>Group Admins</button>
                                }

                                {context && context.auth && context.auth.user_type !== '4' &&
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#groupteacherBox" type="button" role="tab" aria-selected="true" onClick={() => { selectListType('teachers', setRefreshTeacher); }}>Teachers</button>
                                }

                                {context && context.auth && context.auth.user_type !== '5' && context.auth.user_type !== '4' &&
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#partialadminBox" type="button" role="tab" aria-selected="false" onClick={() => { selectListType('partial-admin', setRefreshPartialAdmin); }}>Partial Admins</button>
                                }
                            </div>
                        </nav>
                        <div className="tab-content pt-4">
                            <div className="tab-pane fade" id="groupadminBox" role="tabpanel" tabIndex="0">
                                <DataTable
                                    execute={refreshGroupAdmin}
                                    dt={groupAdmins}
                                    edit_column={{
                                        school_group_code: (records) =>
                                            <span className={`px-3 m-3 text-${records.status === 'active' ? 'green' : 'danger'}`} >
                                                {records.school_group_code}
                                            </span>,

                                        all: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),
                                        created_at: (records) =>
                                            __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),
                                    }}
                                />
                            </div>

                            <div className="tab-pane active" id="studentsBox" role="tabpanel" tabIndex="0">
                                <DataTable
                                    execute={refreshStudent}
                                    dt={students}
                                    edit_column={{
                                        id: (records) =>
                                            <input className="form-check-input mmwh-20 m-0 my_checkboxes" type="checkbox" defaultValue={records.id} onChange={(e) => handleCheckboxChange(e)} checked={studentData.includes(records.id.toString())} />
                                        ,
                                        first_name: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),
                                        group_name: (records) =>
                                            <span>{records.group_name ? records.group_name : '-'}</span>,

                                        section_name: (records) =>
                                            <span>{records.section_name ? records.section_name : '-'}</span>,

                                        workbook_count: (records) =>
                                            <span>{records.workbook_count ? records.workbook_count : 0}</span>,

                                        status: (records) =>
                                            <button type="button" className={`px-3 btn-sm m-3 btn btn-${records.status === 'active' ? 'green' : 'danger'}`} onClick={() => updateStatus(records.id, setRefreshStudent)}>
                                                {records.status === 'active' ? 'Active' : 'Inactive'}
                                            </button>,
                                        all: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),
                                        created_at: (records) =>
                                            __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),

                                        action: (records) =>
                                            <div className="d-flex">
                                                <Link to="/cms/update-user" state={records} className="btn btn-sm text-black" title="Edit">
                                                    <svg className="icon fs-5" role="img"><use href="#icon_pencil" /></svg>
                                                </Link>
                                                {context && context.auth && context.auth.user_type === '4' &&
                                                    <Link to="/cms/user-assignment" state={records} className="btn btn-sm text-black" title="Assignment" href="#">
                                                        <svg className="icon ts-1p5"><use href="#icon_noteadd"></use></svg>
                                                    </Link>
                                                }
                                                {!records.email_verification_status &&
                                                    <span className="btn btn-sm text-danger" title="Verify" onClick={() => verifyStatus(records.id)}>
                                                        <svg className="icon fs-5" role="img"><use href="#icon_checkcircle" /></svg>
                                                    </span>}
                                            </div>
                                    }}
                                />
                            </div>

                            <div className="tab-pane fade" id="partialadminBox" role="tabpanel" tabIndex="0">
                                <DataTable
                                    execute={refreshPartialAdmin}
                                    dt={partialAdmins}
                                    edit_column={{
                                        id: (records) =>
                                            <input className="form-check-input mmwh-20 m-0 my_checkboxes" type="checkbox" defaultValue={records.id} onChange={(e) => handleCheckboxChange(e)} checked={studentData.includes(records.id.toString())} />
                                        ,
                                        first_name: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),
                                        status: (records) =>
                                            <button type="button" className={`px-3 btn-sm m-3 btn btn-${records.status === 'active' ? 'green' : 'danger'}`} onClick={() => updateStatus(records.id, setRefreshPartialAdmin)}>
                                                {records.status === 'active' ? 'Active' : 'Inactive'}
                                            </button>,
                                        all: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),
                                        created_at: (records) =>
                                            __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),

                                        action: (records) =>
                                            <div className="d-flex">
                                                <button type="button" className="btn btn-sm text-black" data-bs-toggle="modal" data-bs-target="#updatePartialAdmin" onClick={(e) => { initialFormState('updatePartialAdminForm', setPartialAdminData); setPartialAdminData(records) }} title="Edit"><svg className="icon fs-5" role="img"><use href="#icon_pencil" /></svg></button>
                                                <button type="button" className="btn btn-sm text-warning mx-1" title="Permission" data-bs-toggle="modal" data-bs-target="#userPermissionModal" onClick={(e) => getPermission(records.id)}><svg className="icon fs-4" role="img"><use href="#icon_users"></use></svg></button>
                                            </div>
                                    }}
                                />
                            </div>

                            <div className="tab-pane fade" id="groupteacherBox" role="tabpanel" tabIndex="0">
                                <DataTable
                                    execute={refreshTeacher}
                                    dt={teachers}
                                    edit_column={{
                                        first_name: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),
                                        status: (records) =>
                                            <button type="button" className={`px-3 btn-sm m-3 btn btn-${records.status === 'active' ? 'green' : 'danger'}`} onClick={() => updateStatus(records.id, setRefreshTeacher)}>
                                                {records.status === 'active' ? 'Active' : 'Inactive'}
                                            </button>,
                                        all: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),
                                        created_at: (records) =>
                                            __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),
                                        action: (records) =>
                                            <div className="d-flex">
                                                <Link to="/cms/update-teacher" state={records} className="btn btn-sm text-black" id="editTeacherBtn" title="Edit">
                                                    <svg className="icon fs-5" role="img"><use href="#icon_pencil" /></svg>
                                                </Link>
                                                {context && context.auth && context.auth.user_type === '5' &&
                                                    <button className="btn btn-sm text-danger btn-lg border border-1 border-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={() => setDeleteTeacherId(records.id)}>
                                                        <svg className="icon fs-5" role="img"><use href="#icon_delete" /></svg>
                                                    </button>}
                                                {context && context.auth && context.auth.user_type === '1' &&
                                                <button className="btn btn-sm text-success btn-lg" title="Login" onClick={() => directLogin(records.id)}>
                                                    <svg className="icon fs-5" role="img"><use href="#icon_login" /></svg>
                                                </button>}
                                            </div>
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete confirmation #2 - Modal */}
            <div className="modal fade " id="deletegroup_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">Delete ?</h3>
                            <p className="m-0 pt-2">
                                This is a destructive action and cannot be reversed.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={() => removeUsers()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Select Group Assign- Modal */}
            <div className="revers-colum modal fade font_space-responsive" id="selectgroupmodal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content overflow-hidden border border-2 border-blue rounded-4">
                        <div className="modal-header bg-grey p-4">
                            <h3 className="modal-title fw-bold mfont-size">Select a group to add users to</h3>
                            <select className="form-select form-select-md maxw-160" onChange={(e) => getGroups(e.target.value)} >
                                <option value="asc">Sort by A - Z</option>
                                <option value="desc">Sort by Z - A</option>
                            </select>
                        </div>
                        <div className="modal-body p-4">
                            <form action="" className="needs-validation" id="assignGroupForm" noValidate>
                                {groupsData && groupsData.length > 0 ?
                                    <div className="row row-cols-1 row-cols-md-2 g-4">
                                        {groupsData.map((item, i) => {
                                            return (
                                                <div className="col">
                                                    <label className="label-cardwrap">
                                                        <input type="radio" value={item.group_code} name="group" id="group" />
                                                        <div className="card rounded-3 p-4">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="pe-3">
                                                                    <h4 className="fw-bold">{item.group_name}</h4>
                                                                    <p className="fw-bold">Enrolment size: {item.user_limit} students</p>
                                                                </div>
                                                                <div className="clone-checkbox rounded-2 mmwh-24">
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="mmwh-36 me-2">
                                                                    <img src={`../images/vector/${getCountryFlagSrc(item.country_code)}`} className="w-100" alt="Flag" />
                                                                </div>
                                                                <span className="text-uppercase text-dim fs-18 fw-bold">{item.country_name}</span>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : <div className="row mt-1"><h4 className="fw-bold text-danger text-center">Group Not Found</h4></div>}
                            </form>
                        </div>
                        <div className="modal-footer justify-content-between p-4 ">
                            <button type="button" className="btn btn-outline-default btn-lg mfont-size" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-green btn-lg mfont-size mb-2" onClick={(e) => assignGroup(e)} >Add to selected group</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Select Group Section Assign- Modal */}
            <div className="revers-colum modal fade font_space-responsive" id="selectgroupsectionmodal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content overflow-hidden border border-2 border-blue rounded-4">
                        <div className="modal-header bg-grey p-4">
                            <h3 className="modal-title fw-bold mfont-size">Select a section to add users to</h3>
                            <select className="form-select form-select-md maxw-160" onChange={(e) => getGroupSection(e.target.value)} >
                                <option value="ASC">Sort by A - Z</option>
                                <option value="DESC">Sort by Z - A</option>
                            </select>
                        </div>
                        <div className="modal-body p-4">
                            <form action="" className="needs-validation" id="assignGroupForm" noValidate>
                                {groupSectionData && groupSectionData.length > 0 ?
                                    <div className="row row-cols-1 row-cols-md-3 g-4">
                                        {groupSectionData.map((item, i) => {
                                            return (
                                                <div className="col">
                                                    <label className="label-cardwrap">
                                                        <input type="radio" value={item.id} name="group_section" id="group_section" />
                                                        <div className="card rounded-3 p-4">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="pe-3">
                                                                    <h4 className="fw-bold">{item.section_name}</h4>
                                                                </div>
                                                                <div className="clone-checkbox rounded-2 mmwh-24">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : <div className="row mt-1"><h4 className="fw-bold text-danger text-center">Section Not Found</h4></div>}
                            </form>
                        </div>
                        <div className="modal-footer justify-content-between p-4 ">
                            <button type="button" className="btn btn-outline-default btn-lg mfont-size" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-green btn-lg mfont-size mb-2" onClick={(e) => assignGroupSection(e)} >Add to selected section</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* User Permission Model */}
            {permissionList && permissionList.length > 0 && <div className="modal fade" tabIndex="-1" id="userPermissionModal" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content shadow-lg overflow-hidden border border-4 border-primary rounded-4">
                        <div className="modal-body p-sm-4">
                            <h3 className="fw-bold text-primary">User Permission</h3>
                            <form className="needs-validation" id="userPermissionForm">
                                <div className="row">
                                    <div className="table-responsive">
                                        <table className="table table-bordered rounded-4">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Permission</th>
                                                    <th>Select</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {permissionList.map((ele, num) => {
                                                    return (
                                                        <tr key={num}>
                                                            <td>{ele.id}</td>
                                                            <td>{ele.name}</td>
                                                            <td>
                                                                <input className={`form-check-input mmwh-20 m-0 permission_checkboxes ${['dashboard', 'users', 'my-account'].includes(ele.slug) ? 'pe-none2' : ''}`} type="checkbox" name='permission[]' defaultValue={ele.slug} defaultChecked='' /></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col text-end">
                                        <button type="button" className="btn btn-danger px-4 ms-2" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                        <button type="button" className="btn btn-theme-primary px-4 ms-2" onClick={(e) => updatePermission(e)} >
                                            {tinyloader ?
                                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div> : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            }

            {/* Modal for Edit Partial Admin */}
            <div className="modal fade" id="updatePartialAdmin" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content border border-4 border-primary rounded-4">
                        <form action="#" method="post" className="needs-validation" id="updatePartialAdminForm" noValidate >
                            <div className="modal-body">
                                <h5 className="modal-title mb-4 fw-bold" >Update Partial Admin</h5>
                                <div className="mt-3 mb-4">
                                    <div className="form-floating">
                                        <input type="hidden" name="id" defaultValue={partialAdminData.id} className="form-control form-control-md" />
                                        <input type="text" id="name" name="name" className="form-control form-control-md" placeholder="Enter name" defaultValue={`${partialAdminData.first_name} ${partialAdminData.last_name}`} required />
                                        <div className="invalid-feedback">Please Enter Full Name.</div>
                                        <label>Full Name<span className="text-danger" id="required_attr">*</span></label>
                                    </div>
                                </div>
                                <div className="mb-1 mt-3">
                                    <div className="form-floating password-wrapper">
                                        <input type="password" id="password" name="password" className="form-control form-control-md" placeholder="Enter Password" autoComplete="off" />
                                        <div className="invalid-feedback">
                                            Minimum length of password should be eight characters.
                                        </div>
                                        <div>
                                            <button type="button" className="btn eye-btn" onClick={(e) => PasswordShow(e, 'password')}>
                                                <svg className="icon pe-none"><use href="#icon_eyeoff"></use></svg>
                                            </button>
                                        </div>
                                        <label>Password</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0">
                                <button type="button" className="btn btn-danger order-sm-1" onClick={(e) => initialFormState('updatePartialAdminForm')} data-bs-dismiss="modal" aria-label="Close">Close</button>
                                <button type="button" className="btn btn-theme-primary order-sm-1" onClick={(e) => editPartialAdmin(e)} disabled={tinyloader}>
                                    <ButtonSpinner load={tinyloader} btnName="Save Changes" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <ConfirmationModal msg={`Delete ?`} method={deleteTeacher} />
        </>
    )
}
export default Users;