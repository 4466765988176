import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExamLoading from "./partials/ExamLoading";
import ExamCalculatorAndTable from "./partials/ExamCalculatorAndTable";
import ExamQuestionList from "./partials/ExamQuestionList";
import ExamTitle from "./partials/ExamTitle";
import ExamButton from "./partials/ExamButton";
import ExamQuestion from "./partials/ExamQuestion";
import { EXAM_INFO, SET_EXAM_RECORD, fetchData, RESTART_EXAM, QUIT_EXAM, SUBMIT_ANSWER, PAGE_CONTENT } from "../../components/Service";
import ExamTime from "./partials/ExamTime";
import _ from "lodash";
import { useCallback } from "react";
import MovingRocket from "../../components/MovingRocket";
import PeriodicTable from "../../components/PeriodicTable";
import ExamCalculator from "../../components/ExamCalculator";
import { stopBackgroundScroll } from "../../components/CommonMethod";
import { Context } from "../../components/Context";

const Exam = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [course] = useState(location && location.state && location.state.course)
    const [workbook] = useState(location && location.state && location.state.workbook)
    const [workbookAttemptType] = useState((location && location.state && location.state.workbook_attempt_type && location.state.workbook_attempt_type === 'assignment') ? 'assignment' : 'workbook')
    const [assignment_id] = useState((location && location.state && location.state.assignment && location.state.assignment.id) ? location.state.assignment.id : '')
    const [mode] = useState(location && location.state && location.state.mode)
    const [questions, setQuestions] = useState(false);
    const [timeCounter, setTimeCounter] = useState('00:00');
    const [startExam, setStartExam] = useState(false);
    const [endExam, setEndExam] = useState(false);
    const [resumeExam, setResumeExam] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(1);
    const [prevQuestionDetails, setPrevQuestionDetails] = useState([]);
    const [prevAnswerDetails, setPrevAnswerDetails] = useState([]);
    const [progress, setProgress] = useState(0)
    const [action, setAction] = useState(location.state && _.upperFirst(location.state.action))
    const [progressData, setProgressData] = useState({ index: 0, value: '', correctText: ['Well Done!'], incorrectText: ['Not quite..'] })
    const [animation, setAnimation] = useState('')
    const [context] = useContext(Context)

    const navigateFromThisPage = () => {
        if(context && context.auth && context.auth.user_type === '2'){
            if (location && location.state && location.state.assignment && workbookAttemptType === 'assignment') {
                navigate('/my-assignments/workbooks', { state: { ...location.state.assignment } });
            } else {
                navigate('/my-workbooks');
            }
        }else{
            navigate('/cms/assigned-workbook');
        }
    }

    window.document.title = "Begin practice";
    var h = 0
    var m = 0
    var s = 0
    var stopCounter

    const examCounter = (timeCounter, setTimeCounter, timeup, setEndExam, resume_exam = false) => {

        if (resume_exam && resumeExam) {
            let [minutes, seconds] = resumeExam.split(':')
            m = minutes
            s = seconds
        } else {
            m = timeCounter
            s = 0
        }
        stopCounter = setInterval(() => {
            if (s === 0) {
                if (m === '00' && s === 0) {
                    clearInterval(endExam)
                    timeup()
                } else {
                    s = 59
                    m--
                }
            }
            if (m < 10) {
                m = '0' + m
                m = m.substr(-2, 2)
            }
            if (s < 10) {
                s = '0' + s
                s = s.substr(-2, 2)
            }
            setTimeCounter(`${m}:${s}`)
            localStorage.setItem('exam_time', `${m}:${s}`)
            s--
        }, 1000);
        setEndExam(stopCounter)
    }

    const practiceCounter = (setTimeCounter, setEndExam, resume_exam = false) => {
        if (resume_exam && resumeExam) {
            let time_split = resumeExam.split(':')
            if (time_split.length === 2) {
                [m, s] = time_split
            } else {
                [h, m, s] = time_split
            }
        }
        stopCounter = setInterval(() => {
            s++
            if (s === 60) {
                s = 0; m++
            }
            if (m === 60) {
                m = 0; h++
            }
            if (m < 10) {
                m = '0' + m; m = m.substr(-2, 2)
            }
            if (s < 10) {
                s = '0' + s; s = s.substr(-2, 2)
            }
            if (h < 10) {
                h = '0' + h; h = h.substr(-2, 2)
            }
            let time = h > 0 ? `${h}:${m}:${s}` : `${m}:${s}`
            localStorage.setItem('exam_time', time)
            setTimeCounter(time)
            setEndExam(stopCounter)
        }, 1000);
    }

    const filterToggle = () => {
        document.getElementById('mobileViewBox').classList.toggle('active');
        stopBackgroundScroll("mobileViewBox");
    }

    useEffect(() => {
        if (!location.state) {
            navigateFromThisPage()
        }
        if (workbook && course && mode) {
            clearInterval(endExam)

            fetchData(`${PAGE_CONTENT}?type=workbook-content`, 'GET', '', false, false, (res) => {
                if (res.records) {
                    let msg = JSON.parse(res.records)
                    setProgressData({
                        ...progressData,
                        correctText: msg.correct_answer && msg.correct_answer.length > 0 ? msg.correct_answer : ['Well Done!'],
                        incorrectText: msg.incorrect_answer && msg.incorrect_answer.length > 0 ? msg.incorrect_answer : ['Not quite..'],
                    })
                }
            }, false, false, '', false);

            fetchData(`${EXAM_INFO}/${workbook.id}/${mode}`, 'get', '', true, false, (res) => {
                if (res.records) {
                    setQuestions(res.records)
                }
            });

            fetchData(`${SET_EXAM_RECORD}/${workbook.id}?workbook_attempt_type=${workbookAttemptType}&assignment_id=${assignment_id}`, 'get', '', true, false, (res) => {
                if (res.records && res.records.id) {
                    setPrevQuestionDetails(res.records.question_details)
                    setPrevAnswerDetails(res.records.answer_details)
                    setActiveQuestion(res.records.question_position + 1)
                    setProgress(res.records.answer_details.length)
                    setResumeExam(res.records.end_time)
                    setAction('Resume')

                    let questions_details = res.records.question_details
                    let ans_sq = { value: '', index: 0 }
                    for (let i in questions_details) {
                        if (ans_sq.value === questions_details[i].correct) {
                            ans_sq = { value: questions_details[i].correct, index: ans_sq.index + 1 }
                        } else {
                            ans_sq = { value: questions_details[i].correct, index: 0 }
                        }
                    }
                    setProgressData({ ...progressData, ...ans_sq })
                }
            }, false, false, '', false);

        }
    }, [workbook, course, mode, location])


    const restartExam = () => {
        clearInterval(endExam)
        setActiveQuestion(1)
        setPrevQuestionDetails([])
        setPrevAnswerDetails([])
        setProgress(0)
        setStartExam(false)
        setResumeExam(false)
        setAction('Restart')
        setProgressData({ ...progressData, index: 0, value: '' })
        fetchData(RESTART_EXAM, 'POST', { workbook_id: workbook.id, workbook_attempt_type: workbookAttemptType, assignment_id: assignment_id }, true, false, (res) => { });
        document.body.classList.remove('body-overflowhidden');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const quitExam = () => {
        clearInterval(endExam)
        if (startExam) {
            fetchData(QUIT_EXAM, 'POST', { workbook_id: workbook.id, end_time: timeCounter, workbook_attempt_type: workbookAttemptType, assignment_id: assignment_id }, true, false, (res) => {
                if (res.records) {
                    navigateFromThisPage()
                }
            });
        } else {
            navigateFromThisPage()
        }
    }

    const timeup = () => {
        clearInterval(endExam)
        fetchData(QUIT_EXAM, 'POST', { workbook_id: workbook.id, end_time: timeCounter, workbook_attempt_type: workbookAttemptType, assignment_id: assignment_id }, true, false, (res) => { });
        setActiveQuestion(questions.length + 1)
    }

    const saveAnswer = useCallback((data, answer = null) => {
        if (mode === 'practice' && data.correct && progressData.value && (progressData.index === 1 || progressData.index === 3)) {
            if (progressData.index === 1) {
                setAnimation('amazing_work')
                setTimeout(() => { setAnimation('') }, 3000);
            } else {
                setAnimation('rocket')
                setTimeout(() => { setAnimation('') }, 6000);
            }
        }
        setProgressData({ ...progressData, index: progressData.value === data.correct ? progressData.index + 1 : 0, value: data.correct })
        let exam_time = localStorage.getItem('exam_time')
        let questions_details = [...prevQuestionDetails, data];
        let answer_details = [...prevAnswerDetails, answer];
        let complete_exam = data.order === questions.length ? true : false
        setProgress(answer_details.length)
        fetchData(SUBMIT_ANSWER, 'POST', { ...data, questions_details: questions_details, answer_details: answer_details, complete_exam: complete_exam, workbook_id: workbook.id, exam_mode: mode, end_time: exam_time, workbook_attempt_type: workbookAttemptType, assignment_id: assignment_id }, true, false, (res) => { }, false, false, '', false);
    }, [questions, prevQuestionDetails, prevAnswerDetails, workbook, mode]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (questions.length === activeQuestion - 1) {
            let total_time = timeCounter
            let state = { questions: questions, questions_details: prevQuestionDetails, answer_details: prevAnswerDetails, total_time: total_time, course: course, workbook: workbook, mode: mode, workbook_attempt_type: workbookAttemptType, assignment: location.state.assignment }
            if (mode === 'exam') {
                let [minutes, seconds] = timeCounter.split(':')
                seconds = 60 - seconds
                if (seconds === 60) {
                    seconds = 0
                    minutes = workbook.time_limit - minutes
                } else {
                    minutes = workbook.time_limit - minutes - 1
                }
                if (minutes < 10) {
                    minutes = '0' + minutes
                    minutes = minutes.substr(-2, 2)
                }
                if (seconds < 10) {
                    seconds = '0' + seconds
                    seconds = seconds.substr(-2, 2)
                }
                total_time = minutes + ':' + seconds
                navigate(`/workbook/${_.kebabCase(workbook && workbook.title)}/review`, { state: { ...state, total_time: total_time } })
            } else {
                navigate(`/workbook/${_.kebabCase(workbook && workbook.title)}/review`, { state: { ...state, clapping_time: (Date.now() + 2000) } })
            }
        }
    }, [activeQuestion])

    return (<>
        {location.state &&
            <>
                <nav className="navbar navbar-expand-lg navbar-front bg-white position-sticky top-0 zindex-100 shadow-none z-index-responsive-top" >
                    <div className="container py-4">
                        <ExamTitle workbook={workbook} course={course} />
                        <button type="button" className="btn btn-link p-2 ms-auto d-block d-lg-none" onClick={() => filterToggle()}>
                            <svg className="icon ts-1p4"><use href="#icon_filter"></use></svg>
                        </button>
                        <div className="col-lg-4 d-lg-inline d-none text-end" id="asideMobile">
                            <ExamButton startExam={startExam} restartExam={restartExam} quitExam={quitExam} mode={mode} />
                        </div>
                    </div>
                </nav>

                {startExam &&
                    <div className="container d-none">
                        <div className="row g-4 exam-progress">
                            <div className="col-lg-9 bg-white">
                                {mode === 'exam' ? <span className="fw-bold text-blue mb-2">&#128512; Keep going</span>
                                    : <>
                                        {progressData.value === true && <>
                                            {progressData.index === 0 && <span className="fw-bold text-blue mb-2">&#128512;  Keep It Up!</span>}
                                            {progressData.index === 1 && <span className="fw-bold text-blue mb-2">&#9996;  Nice job</span>}
                                            {progressData.index > 1 && <span className="fw-bold text-yellow mb-2">&#128293; {progressData.index + 1} in a row</span>}
                                        </>}
                                        {progressData.value === false && <span className="fw-bold text-blue mb-2">&#128566; Ooops!</span>}
                                        {progressData.value === '' && <span className="fw-bold text-blue mb-2">&#128512; Let's start</span>}
                                    </>
                                }
                                <div className="progress mb-3">
                                    <div className={`${mode === 'practice' && progressData.value === true && progressData.index > 1 ? 'bg-yellow' : 'bg-sky'} progress-bar`} role="progressbar" aria-label="Basic example" style={{ width: `${(100 / questions.length) * progress}%` }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>}

                {startExam &&
                    <div className="container">
                        <div className="row g-4 exam-sidebar justify-content-end">
                            <div className="col-lg-3 pt-3" id="side_scrooling">
                                <div className="aside-filter" id="mobileViewBox">
                                    <div className="aside-innerfilter">
                                        <div className="d-flex d-lg-none align-items-center justify-content-between pb-3">
                                            <button type="button" className="btn" onClick={() => filterToggle()} title="Close">
                                                <svg className="icon ts-1p5 pe-none"><use href="#icon_clear"></use></svg>
                                            </button>
                                        </div>
                                        <div className="ms-auto d-lg-inline d-none">
                                            <ExamTime mode={mode} workbook={workbook} timeCounter={timeCounter} />
                                        </div>
                                        <ExamQuestionList mode={mode} questions={questions} activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion} prevQuestionDetails={prevQuestionDetails} />
                                        <ExamCalculatorAndTable course={course} filterToggle={filterToggle} mode={mode} questions={questions} activeQuestion={activeQuestion} />
                                        <div className="d-lg-none mt-2">
                                            <ExamButton startExam={startExam} restartExam={restartExam} quitExam={quitExam} filterToggle={filterToggle} mode={mode} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                <div className="container">
                    {startExam ?
                        <div className="row g-4 exam-body">
                            <div className="col-lg-9 bg-white1">
                                <div className="progress-sticky">
                                    {mode === 'exam' ? <span className="fw-bold text-blue mb-2">&#128512; Keep going</span>
                                        : <>
                                            {progressData.value === true && <>
                                                {progressData.index === 0 && <span className="fw-bold text-blue mb-2">&#128512;  Keep It Up!</span>}
                                                {progressData.index === 1 && <span className="fw-bold text-blue mb-2">&#9996;  Nice job</span>}
                                                {progressData.index > 1 && <span className="fw-bold text-yellow mb-2">&#128293; {progressData.index + 1} in a row</span>}
                                            </>}
                                            {progressData.value === false && <span className="fw-bold text-blue mb-2">&#128566; Ooops!</span>}
                                            {progressData.value === '' && <span className="fw-bold text-blue mb-2">&#128512; Let's start</span>}
                                        </>
                                    }
                                    <div className="progress mb-3">
                                        <div className={`${mode === 'practice' && progressData.value === true && progressData.index > 1 ? 'bg-yellow' : 'bg-sky'} progress-bar`} role="progressbar" aria-label="Basic example" style={{ width: `${(100 / questions.length) * progress}%` }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className="d-lg-none">
                                    <ExamTime mode={mode} workbook={workbook} timeCounter={timeCounter} />
                                </div>
                                <ExamQuestion animation={animation} mode={mode} questions={questions} activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion} setPrevQuestionDetails={setPrevQuestionDetails} setPrevAnswerDetails={setPrevAnswerDetails} saveAnswer={saveAnswer} setProgress={setProgress} progressData={progressData} questions_details={prevQuestionDetails} answer_details={prevAnswerDetails} />
                            </div>
                        </div>
                        :
                        <ExamLoading mode={mode} action={action} questions={questions} setEndExam={setEndExam} setStartExam={setStartExam} examCounter={() => examCounter(workbook.time_limit, setTimeCounter, timeup, setEndExam, true)} practiceCounter={() => practiceCounter(setTimeCounter, setEndExam, true)}>
                            <div className="aside-filter d-lg-none" id="mobileViewBox">
                                <div className="aside-innerfilter">
                                    <div className="d-flex d-lg-none align-items-center justify-content-between pb-3">
                                        <button type="button" className="btn" onClick={() => filterToggle()} title="Close"><svg className="icon ts-1p5"><use href="#icon_clear"></use></svg></button>
                                    </div>
                                    <ExamButton startExam={startExam} restartExam={restartExam} quitExam={quitExam} filterToggle={filterToggle} />
                                </div>
                            </div>
                        </ExamLoading>
                    }
                    {animation === 'rocket' && <MovingRocket />}
                </div>

                {course.periodic_table && <PeriodicTable image={course.periodic_table} heading="Periodic Table" />}
                {course.calculator === "yes" && <ExamCalculator />}
                {mode && questions && activeQuestion && questions[activeQuestion - 1] && <PeriodicTable video={questions[activeQuestion - 1].video_url} heading="Video Solution" />}

                <div className="modal fade" id="restartModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border border-2 border-blue p-2 rounded-4">
                            <div className="modal-header border-0">
                                <h3 className="fw-bold text-blue">Restart this Workbook</h3>
                            </div>
                            <div className="modal-body border-0">
                                <p>Your progress so far will not be saved.</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-default btn-lg me-2" title="Keep Working" data-bs-dismiss="modal">Keep Working</button>
                                <button className="btn btn-yellow btn-lg me-2" title="Restart" data-bs-dismiss="modal" onClick={restartExam}>Restart</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="quitModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border border-2 border-blue p-2 rounded-4">
                            <div className="modal-header border-0">
                                <h3 className="fw-bold text-blue">Quit this Workbook</h3>
                            </div>
                            <div className="modal-body">
                                <p>Your progress will be saved. You will resume from the last question you answered the next time you open this workbook.</p>
                            </div>
                            <div className="modal-footer border-0">
                                <button className="btn btn-outline-default btn-lg me-2" title="Keep Working" data-bs-dismiss="modal">Keep Working</button>
                                <button className="btn btn-danger btn-lg me-2" title="Quit" data-bs-dismiss="modal" onClick={quitExam}>Quit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>)
}

export default Exam