import React, {useEffect, useState } from "react"
import { Popover, Tooltip } from "bootstrap";
import QuestionSolution from "../../../../components/QuestionSolution";
import MathElement from "../../../../components/MathElements";
const TrueAndFalse = (props) => {

    const [question] = useState(props && props.question)
    const [serialNo] = useState(props && props.serialNo)
    const [option] = useState(props.question && props.question.option ? props.question.option : '')
    const [checkAnswerClass, setCheckAnswerClass] = useState(false)
    const [progressData] = useState(props.progressData ?? {})
    const [progressText, setProgressText] = useState(props.progressText ?? '')

    const selectOption = () => {
        let check_options = document.querySelectorAll(`#${question.question_type}${question.order} .option[type=radio]:checked`).length
        if(option.length && check_options === option.length){
            let check_btn = document.getElementById(props.mode === 'exam' ? `${question.order}_submit_answer` :  `${question.order}_check_answer`)
            if(check_btn) check_btn.classList.remove('disabled')
        }
    }

    const saveAnswer = (check=false) => {
        let is_correct = false
        let options = []
        let answer = []
        document.querySelectorAll(`#${question.question_type}${question.order} .option[type=radio]:checked`).forEach((e) => {
            e.nextSibling.classList.add('event-none')
            let correct = e.value === 'true' ? true : false
            answer = [...answer, {'answer': correct}]
            options.push(correct)
            if(check){
                if(correct){
                    e.parentElement.parentElement.insertAdjacentHTML('beforeend',
                    `<div class="col-3 ms-auto">
                    <img src='/images/checkbox.png' alt='right' />
                    <button type="button" class="btn ms-0 p-0 border-white text-blue text-decoration-underline ms-2 fw-bold" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="focus hover" data-bs-template='<div class="popover shadow maxw-900 border-blue border border-2" role="popover"><div class="popover-arrow  border-blue "></div><div class="popover-inner"><div class="row "><div class="col-sm-4"><img src="/images/why-male.png"  alt="Male"/></div><div class="col-sm-8"><p class="mb-0 pt-2 px-3 fw-medium text-blue"></p><p class="mb-0 px-3 text-blue fw-medium">${e.dataset.reasoning}</p></div></div></div></div>'>${e.dataset.reasoning ? 'Why?' : ''}</button>
                    </div>`);
                }else{
                    e.parentElement.parentElement.insertAdjacentHTML('beforeend',
                    `<div class="col-3 ms-auto">
                    <img src='/images/crossbox.png' alt='wrong' />
                    <button type="button" class="btn ms-0 p-0 border-white text-blue text-decoration-underline ms-2 fw-bold" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="focus hover" data-bs-template='<div class="popover shadow maxw-900 border-blue border border-2" role="popover"><div class="popover-arrow  border-blue "></div><div class="popover-inner"><div class="row "><div class="col-sm-4"><img src="/images/why-male.png"  alt="Male"/></div><div class="col-sm-8"><p class="mb-0 pt-2 px-3 fw-medium text-blue"></p><p class="mb-0 px-3 text-blue fw-medium">${e.dataset.reasoning}</p></div></div></div></div>'>${e.dataset.reasoning ? 'Why?' : ''}</button>
                    </div>`);
                }
            }
            is_correct = options.every((op) => op)
        })

        document.querySelectorAll(`#${question.question_type}${question.order} .option[type=radio]`).forEach((e) => {
            e.nextSibling.classList.add('event-none')
        })
          
        if(props.mode !== 'review'){
            let index = progressData.value === is_correct ? progressData.index + 1 : 0
            let progress_text = is_correct ? (progressData.correctText[index] ?? progressData.correctText[0]) : (progressData.incorrectText[index] ?? progressData.incorrectText[0])
            let submitAnswerInfo = {question_id: question.id, correct: is_correct, order: question.order, question_type: question.question_type, progress_text: progress_text}
            setProgressText(progress_text)
            props.setPrevQuestionDetails((prev) => ([...prev, submitAnswerInfo]))
            props.setPrevAnswerDetails((prev) => ([...prev, {answerArr: answer}]))
            props.saveAnswer(submitAnswerInfo, {answerArr: answer})
            // props.setProgress(question.order)
            Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
            Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
        }
        return is_correct;
    }

    const checkAnswer = () => {
        let is_correct = saveAnswer(true)
        setCheckAnswerClass(is_correct ? 'right' : 'wrong')
    }
    

    const submitAnswer = () => {
        if(props.mode && props.mode === 'exam'){
            saveAnswer()
        }
        props.setActiveQuestion((prev) => prev+1)
    }

    const reviewAnswer = () => {
        let is_correct = saveAnswer(true)
        setCheckAnswerClass(is_correct ? 'right' : 'wrong')
    }

    useEffect(() => {
        if(props.mode === 'review'){
            reviewAnswer()
        }
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
    }, [])

        
return (
    <>
        <div className={`fs-18 question border border-3 p-3 rounded-4 mb-4 ${checkAnswerClass === 'right' ? 'border-green' : checkAnswerClass ? 'border-danger' : 'border-blue'}`}>
            <h6 className={`fs-18 fw-bold pb-2 pt-2 ${checkAnswerClass === 'right' ? 'text-green' : checkAnswerClass ? 'text-danger' : 'text-blue'}`}>
                Question {serialNo}
                <span>
                <button type="button" className="btn border-0" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-template="<div class=&quot;popover shadow&quot; role=&quot;popover&quot;><div class=&quot;popover-arrow&quot;></div><div class=&quot;popover-inner pt-3 px-3 fw-medium &quot;>Read the statement and decide if you think it is true or false. Click ‘T’ to label it True and ‘F’ to label it False.<div class=&quot;row &quot;><div class=&quot;col-sm-12 &quot;><div><img src='/images/ex-truefalse.png'class='img-fluid  p-4'></img></div></div></div>"><svg className="icon ts-1p5"><use href="#icon_query"></use></svg></button>
                </span>
            </h6>

            <span className="raw-question"><MathElement ele_id={`question_${question.order}_div`} elements={question.question}/></span>

            <div className="mt-4" id={`${question.question_type}${question.order}`}>
                {option.length > 0 && option.map((item, index) => {
                    return(
                        <div className="my-3 d-flex" key={index}>
                            <div className="col-md-2 col-sm-3 d-flex align-items-center pe-2">
                                <input type="radio" className="btn-check click-statement option" id={`option_${question.order}_${index*2}`} name={item.option} defaultChecked={props.answer_details ? (props.answer_details[index].answer === item.is_correct) : false} value={item.is_correct} data-reasoning={item.reasoning} onClick={selectOption} />
                                <label htmlFor={`option_${question.order}_${index*2}`} className="shadow-md">T</label>
                                <input type="radio" className="btn-check click-statement option" id={`option_${question.order}_${(index*2)+1}`} name={item.option} defaultChecked={props.answer_details ? (props.answer_details[index].answer === !item.is_correct) : false} value={!item.is_correct} data-reasoning={item.reasoning} onClick={selectOption} />
                                <label htmlFor={`option_${question.order}_${(index*2)+1}`} className="shadow-md ms-2">F</label>
                            </div>
                            <div className="col-md-7 col-sm-6">
                                <span className=""><MathElement ele_id={`question_${question.order}_option_${index}`} elements={item.option}/></span>
                            </div>
                        </div> 
                    )
                })}     
            </div>

            <div className="button d-flex justify-content-between mt-4">
                {checkAnswerClass && question && question.solution_image && <button className="btn btn-outline-default btn-lg minw-90 m-2 ms-0" data-bs-target="#solutionModal" data-bs-toggle="modal">Solution</button>}
                {checkAnswerClass && <span className={`fw-bold mt-3 fs-5 me-2 ${checkAnswerClass === 'right' ? 'text-green' : 'text-danger'}`}>{progressText}</span>}
                {props.mode && props.mode === 'practice' && !checkAnswerClass && <button type="button" id={`${question.order}_check_answer`} className="btn btn-lg ms-auto btn-theme-primary m-2 me-0 disabled" onClick={checkAnswer}>Check</button>}
                {(checkAnswerClass || props.mode === 'exam') &&  <button id={`${question.order}_submit_answer`} className={`btn btn-lg m-2 ms-auto me-0 ${props.mode === 'exam' && 'btn-theme-primary disabled'} ${checkAnswerClass === 'right' ? 'btn-green' : checkAnswerClass ? 'btn-danger' : 'border-blue'}`} onClick={() => submitAnswer()}>Next</button>}
            </div>
        </div>
        {question.solution_image && <QuestionSolution image={question.solution_image} />}
    </>

)
}
export default TrueAndFalse;