import React, { useEffect,useState } from "react";
import MathElement from "../../../../components/MathElements";
import QuestionSolution from "../../../../components/QuestionSolution"
import DragOption from "./DragAndDrop/DragOption";
import DragZone from "./DragAndDrop/DragZone";

const DragToZone = (props) => {
    const [question] = useState(props.question)
    const [serialNo] = useState(props.serialNo ?? 0)
    const [questionReasoning] = useState(props.question && props.question.reasoning && JSON.parse(props.question.reasoning))
    const [answerArr, setAnswerArr] = useState(props && props.answer_details && props.answer_details.answerArr ? props.answer_details.answerArr : '')
    const [correctAnswer, setCorrectAnswer] = useState()
    const [enableAnswerButton, setEnableAnswerButton] = useState(false)
    const [questionStatus, setQuestionStatus] = useState(props.questions_details ? (props.questions_details.correct ? 'correct' : 'incorrect'): 'default');
    const [progressData] = useState(props.progressData ?? {})
    const [progressText, setProgressText] = useState(props.progressText ?? '')

    const checkQuestionAttempted = () => {
        if(answerArr){
            let nonEmptyAnswer = Object.values(answerArr).filter(item => item.length);
            let answerLength = Object.values(nonEmptyAnswer).length
            let questionLength = question && question.option && JSON.parse(question.option).length

            if(answerLength === questionLength){
                setEnableAnswerButton(true)
            }else{
                setEnableAnswerButton(false)
            }
        }else{
            if(props.mode && props.mode === 'review' && questionStatus === 'default'){
                setQuestionStatus('incorrect')
            }
        }
    }

    const checkAnswer = (check = true) => {
        let is_correct = 'correct'

        if(check){
            question && question.option && JSON.parse(question.option).map(option => {
                answerArr[option].map(item => {
                    if(correctAnswer[item.label] !== option){
                        is_correct = 'incorrect'
                    }
                })
            })
            
            setQuestionStatus(is_correct)
        }
        
        if(props.mode !== 'review'){
            let check = is_correct === 'correct' ? true : false
            let index = progressData.value === check ? progressData.index + 1 : 0
            let progress_text = check ? (progressData.correctText[index] ?? progressData.correctText[0]) : (progressData.incorrectText[index] ?? progressData.incorrectText[0])
            let submitAnswerInfo = {question_id: question.id, correct: is_correct === 'correct' ? true : false, order: question.order, question_type: question.question_type, progress_text: progress_text}
            setProgressText(progress_text)
            props.setPrevQuestionDetails((prev) => ([...prev, submitAnswerInfo]))
            props.setPrevAnswerDetails((prev) => ([...prev, {answerArr: answerArr}]))
            props.saveAnswer(submitAnswerInfo, {answerArr: answerArr})
            // props.setProgress(question.order)
        }
    }

    const submitAnswer = () => {
        if(props.mode && props.mode === 'exam'){
            checkAnswer(true)
        }
        props.setActiveQuestion((prev) => prev + 1)
    }

    const correctAnswerData = () => {        
        let correctAnswer = {};

        if(question && question.answers){
            for(let i = 0; i < question.answers.length; i++){
                correctAnswer[question.answers[i].phrase] = question.answers[i].answer
            }
        }
        
        setCorrectAnswer(correctAnswer)
    }

    useEffect(() => {
        checkQuestionAttempted()
    },[answerArr])

    useEffect(() => {
        correctAnswerData()
    }, [])

    return (
        <>  
            <div className={`fs-18 question border border-3 p-3 rounded-4 mb-4 ${questionStatus}_border_es`}>
                <h6 className={`fs-18 fw-bold pb-2 pt-2 ${questionStatus}_text_es`}>Question{serialNo}
                    <span><button type="button" className="btn border-0" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-template="<div class=&quot;popover shadow&quot; role=&quot;popover&quot;><div class=&quot;popover-arrow&quot;></div><div class=&quot;popover-inner pt-3 px-3 fw-medium &quot;>Click and drag a potential answer to a dropzone. For this question, you can add multiple elements to each drop zone.<div class=&quot;row &quot;><div class=&quot;col-sm-12 &quot;><div><img src='/images/ex-compound.png'class='img-fluid  p-4'></img></div></div></div>"><svg className="icon ts-1p5"><use href="#icon_query"></use></svg></button></span>
                </h6>

                <span className="question_title raw-question" id="question_title"><MathElement ele_id={`question_${question.order}_div`} elements={question.question}/></span>

                <div className={`mt-4 ${questionStatus !== 'default' && 'disable-drag-option'}`}>
                    { question && question.phrase && JSON.parse(question.phrase).map((item, i) => {
                        return(
                            <DragOption d_id={`drag_${question.order}`} key={i} drag_option_id={`drag_option_${i}`} targetKey="optionDropZone" label={item} image="" isImage={false} isHalfDragButton={false}/>
                        )
                    })}
                </div>

                <div className="row mt-4">
                    { question && question.option && JSON.parse(question.option).map((item, i) => {
                        return(
                            <div className="col mb-3" key={i}>
                                <div className="text-center"><span className="border-0 bg-white" id={`item_mathml_${i}`}><MathElement ele_id={`question_${question.order}_option_${i}`} elements={item}/></span></div>
                                <div className={`${questionStatus !== 'default' && 'query-place-div'}`}>
                                    <DragZone id={`drag_${question.order}`} targetKey="optionDropZone" isHalfDragButton={false} isMultiDrop={true} matchWidth={item} setAnswerArr={setAnswerArr} questionReasoning={questionReasoning} correctAnswer={correctAnswer} questionStatus={questionStatus} optionItems={props && props.answer_details && props.answer_details.answerArr[item]}/>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="button d-flex justify-content-between mt-4">
                    { questionStatus !== 'default' && question && question.solution_image && <button className="btn btn-outline-default btn-lg minw-90 m-2 ms-0" data-bs-target="#solutionModal" data-bs-toggle="modal">Solution</button> }
                    { questionStatus !== 'default' && <span className={`fw-bold mt-3 fs-5 ${questionStatus}_text_es`}>{progressText}</span> }
                    { props.mode && props.mode === 'practice' && questionStatus === 'default' && <button type="button" className={`btn btn-lg ms-auto btn-theme-primary m-2 me-0 ${!enableAnswerButton && 'disabled'}`} onClick={() => checkAnswer()} >Check</button> }
                    { ((props.mode && props.mode === 'exam') || questionStatus !== 'default') && <button className={`btn btn-lg m-2 ms-auto me-0 ${props.mode === 'exam' && !enableAnswerButton && 'disabled'} ${props.mode === 'exam' && 'btn-theme-primary'} ${questionStatus === 'correct' ? 'btn-green' : (questionStatus === 'incorrect' ? 'btn-danger' : 'border-blue') }`} onClick={() => submitAnswer()}>Next</button> }
                </div>
            </div>

            <QuestionSolution image={props && props.question && props.question.solution_image ? props.question.solution_image : ''} />
        </>
    )
}
export default DragToZone;