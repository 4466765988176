import React, { useEffect, useState } from "react";
import { ASSET_ENDPOINT } from "../components/Service";
import { noImageHandle } from "../components/CommonMethod"

const PeriodicTable = (props) => {
    const [videoUrl] = useState(props && props.video ? `https://www.youtube.com/embed/${props.video.substring(props.video.lastIndexOf("/") + 1)}` : '')
    const [tablePosition, setTablePosition] = useState('')

    useEffect(() => {
        let videoModal = document.getElementById('videoModal')

        if (videoModal) {
            videoModal.addEventListener('hidden.bs.modal', function () {
                document.getElementById('ytplayer').setAttribute('src', '');
            })

            videoModal.addEventListener('show.bs.modal', function () {
                document.getElementById('ytplayer').setAttribute('src', videoUrl);
            })


        }

    }, [props.video])

    return (<>
        {props && props.image &&
            <div className="modal fade" id="periodicModal" tabIndex="-1" >
                <div className={`modal-dialog modal-dialog-centered modal-lg ${tablePosition}`}>
                    <div className="modal-content border border-2 border-blue p-2 rounded-4">
                        <div className="modal-header border-0 ">
                            <h3 className="fw-bold text-blue">Periodic Table</h3>
                        </div>
                        <div className="modal-body">
                            <div className="col-sm-12">
                                <img src={props && props.image && ASSET_ENDPOINT + props.image} className="img-fluid" style={{ objectfit: 'cover' }} onError={noImageHandle} alt={props.heading && props.heading} />


                                <div className="d-none d-lg-block arrow-right_left1 pt-3">
                                    <button className="btn btn-sm btn-primary me-2" disabled={tablePosition === 'left_arrow'} onClick={() => setTablePosition('left_arrow')}>
                                        <svg className="icon"><use href="#icon_arrowback"></use></svg>
                                    </button>
                                    <button className="btn btn-sm btn-primary me-2" disabled={tablePosition === ''} onClick={() => setTablePosition('')}>
                                        ||
                                    </button>
                                    <button className="btn btn-sm btn-primary" disabled={tablePosition === 'right_arrow'} onClick={() => setTablePosition('right_arrow')}>
                                        <svg className="icon"><use href="#icon_arrowforward"></use></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {props && props.video &&
            <div className="modal fade" id="videoModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content border border-2 border-blue p-2 rounded-4">
                        <div className="modal-header border-0 ">
                            <h3 className="fw-bold text-blue">Video</h3>
                        </div>
                        <div className="modal-body">
                            <div className="col-sm-12">
                                <iframe width="100%" height="400px" id="ytplayer" title="Video Solution"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)

}
export default PeriodicTable;
