import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PasswordShow, validateForm, ButtonSpinner, initialFormState } from "../../components/CommonMethod";
import { fetchData, RESET_PASSWORD } from '../../components/Service';
import queryString from 'query-string'
import { useContext } from 'react';
import { Context } from '../../components/Context';

const ResetPassword = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            resetPassword(e)
        }
    }

    const resetPassword = (e) => {
        let query = queryString.parse(location.search)
        let formData = new FormData(document.getElementById('resetForm'));

        formData.append('token', query.token)

        if (validateForm(e, 'resetForm')) {
            setTinyloader(true);

            fetchData(RESET_PASSWORD, 'POST', formData, false, true, (res) => {
                setTinyloader(false);

                if (res.success) {
                    initialFormState("resetForm")
                    localStorage.removeItem("waae-token");
                    localStorage.removeItem('waae-admin-token')
                    setContext('')
                    navigate('/')
                }
            });
        };
    }

    return (
        <section className="d-flex justify-content-center align-items-center w-100 min-vh-100">
            <div className="container">
                <div className="row justify-content-center">
                    <form className="col-sm-7 col-md-6 col-lg-5 p-4 px-lg-5 needs-validation" id="resetForm" noValidate>
                        <Link to="/" className="text-decoration-none text-dark">
                            <div className="text-center mb-5 pe-none">
                                <img src="/images/logo.svg" alt="Logo" />
                                <p className="fs-18 fw-bold pt-3">Content management system</p>
                            </div>
                        </Link>
                        <h4 className="text-center my-3">Reset Password</h4>
                        <div className="mb-4">
                            <div className="password-wrapper">
                                <input type="password" name="password" id="password" placeholder="Password" className="form-control form-control-md" onKeyUp={(e) => changeHandler(e)} autoComplete="current-password" required />
                                <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'password')}>
                                    <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                                </button>
                                <div className="invalid-feedback">
                                    The password field is required.
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="password-wrapper">
                                <input type="password" name="password_confirmation" id="password_confirmation" placeholder="New Confirm Password" className="form-control form-control-md" onKeyUp={(e) => changeHandler(e)} autoComplete="current-password" required />
                                <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'password_confirmation')}>
                                    <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                                </button>
                                <div className="invalid-feedback">
                                    The new confirm password field is required.
                                </div>
                            </div>
                        </div>
                        <div className="text-end">
                            <button type="button" className="btn btn-theme-primary minw-110 mb-3 mb-sm-2 me-auto me-sm-0 order-sm-1" onClick={(e) => resetPassword(e)} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Reset Password" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword
