import { useEffect, useState } from "react"
import { Popover, Tooltip } from "bootstrap";
import QuestionSolution from "../../../../components/QuestionSolution"
import _ from "lodash"
import MathElement from "../../../../components/MathElements";

const EnergyDiagram = (props) =>{
    const [question] = useState(props.question)
    const [serialNo] = useState(props.serialNo ?? 0)
    const [saveMatchAnswer, setSaveMatchAnswer] = useState({'1s': 0, '2s' : 0, '2p' : 0, '3s' : 0, '3p': 0, '4s': 0, '3d': 0, '4p' : 0})
    const [saveAnswer, setSaveAnswer] = useState({'1s': '', '2s' : '', '2p' : '', '3s' : '', '3p': '', '4s': '', '3d': '', '4p' : ''})
    const [enableAnswerButton, setEnableAnswerButton] = useState(false)
    const [questionStatus, setQuestionStatus] = useState(props.questions_details ? (props.questions_details.correct ? 'correct' : 'incorrect'): 'default');
    const [progressData] = useState(props.progressData ?? {})
    const [progressText, setProgressText] = useState(props.progressText ?? '')

    const addArrow = (e) => {
        let total_val = 0;
        let total_current_val = 0;
        let inputLength = document.querySelectorAll(`[data-edit='${e.target.dataset.edit}']`);

        inputLength.forEach((ele, i) => {
            total_current_val = total_current_val + ele.childElementCount
        })

        //console.log(e.target.childElementCount)

        if(e.target.childElementCount === 0){
            e.target.innerHTML = '<svg class="icon fw-bold event-none"><use href="#icon_arrowtop"></use></svg>';
        }else if(e.target.childElementCount === 1 && total_current_val >= inputLength.length){
            e.target.innerHTML = '';
            e.target.innerHTML = '<svg class="icon fw-bold event-none"><use href="#icon_arrowtop"></use></svg><svg class="icon ms-1 fw-bold event-none"><use href="#icon_arrowdown"></use></svg>';
        }else{
            console.log(total_current_val, inputLength.length)
            if(inputLength.length > 1){
                if(total_current_val - 1 <= inputLength.length) {
                    e.target.innerHTML = '';
                }else{
                    e.target.innerHTML = '<svg class="icon fw-bold event-none"><use href="#icon_arrowtop"></use></svg>';
                }
            }else{
                e.target.innerHTML = '';
            }
            // if((inputLength.length > 0 && total_current_val <= inputLength.length){
            //     e.target.innerHTML = '';
            // }else{
            //    // e.target.innerHTML = '';
            // }
        }

        inputLength.forEach((ele, i) => {
            total_val = total_val + ele.childElementCount
            setSaveAnswer(prev => ({...prev, [e.target.dataset.edit] : {...prev[e.target.dataset.edit], [i] : ele.childElementCount ? ele.childElementCount : 0 }}))
        })

        setSaveMatchAnswer(prev => ({ ...prev, [e.target.dataset.edit] : total_val}))
    }
    

    const checkAnswer = (check = true) => {
        let is_correct = 'correct'
        
        if(check){
            question && question.answers && question.answers.forEach(item => {
                document.querySelectorAll(`[data-edit='${item.phrase}']`).forEach(ele => {
                    ele.classList.add('event-none')
                })

                let save_ans = saveMatchAnswer && saveMatchAnswer[item.phrase]
                let check_ans = _.isInteger(parseInt(item.answer)) ? parseInt(item.answer) : 0
                
                if(save_ans !== check_ans){
                    is_correct = 'incorrect'
                }
            })

            setQuestionStatus(is_correct)
        }

        if(props.mode !== 'review'){
            let check = is_correct === 'correct' ? true : false
            let index = progressData.value === check ? progressData.index + 1 : 0
            let progress_text = check ? (progressData.correctText[index] ?? progressData.correctText[0]) : (progressData.incorrectText[index] ?? progressData.incorrectText[0])
            let submitAnswerInfo = {question_id: question.id, correct: is_correct === 'correct' ? true : false, order: question.order, question_type: question.question_type, progress_text: progress_text}
            setProgressText(progress_text)
            props.setPrevQuestionDetails((prev) => ([...prev, submitAnswerInfo]))
            props.setPrevAnswerDetails((prev) => ([...prev, {saveAnswer: saveAnswer}]))
            props.saveAnswer(submitAnswerInfo, {saveAnswer: saveAnswer})
            // props.setProgress(question.order)
        }

        setTimeout(() => {
            Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
            Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
        }, 100);
    }

    const submitAnswer = (check = true) => {

        if(props.mode && props.mode === 'exam'){
            checkAnswer()
        }
        props.setActiveQuestion((prev) => prev + 1)
    }

    useEffect(() => {
        if(saveMatchAnswer){
            let valueLength = Object.values(saveMatchAnswer).filter((item) => item)
            if(valueLength.length > 0){
                setEnableAnswerButton(true)
            }else{
                setEnableAnswerButton(false)
            }
        }
        
    },[saveMatchAnswer])

    useEffect(() => {
        if(props && props.answer_details && props.answer_details.saveAnswer){
            Object.keys(props.answer_details.saveAnswer).forEach(item => {
                if(props.answer_details.saveAnswer[item]){
                    let arrow_value = props.answer_details.saveAnswer[item]
                    let ele = document.querySelectorAll(`[data-edit='${item}']`)
                    for(let i = 0;i <= ele.length-1; i++){
                        if(arrow_value[i] === 2){
                            ele[i].innerHTML = '<svg class="icon fw-bold event-none"><use href="#icon_arrowtop"></use></svg><svg class="icon ms-1 fw-bold event-none"><use href="#icon_arrowdown"></use></svg>'
                        }else if(arrow_value[i] === 1){
                            ele[i].innerHTML = '<svg class="icon fw-bold event-none"><use href="#icon_arrowtop"></use></svg>';
                        }else{
                            ele[i].innerHTML = ''
                        }
                    }
                }
                document.querySelectorAll(`[data-edit='${item}']`).forEach(ele => {
                    ele.classList.add('event-none')
                })
            })
        }else{
            if(props.mode && props.mode === 'review' && questionStatus === 'default'){
                setQuestionStatus('incorrect')
                question && question.answers && question.answers.forEach(item => {
                    document.querySelectorAll(`[data-edit='${item.phrase}']`).forEach(ele => {
                        ele.classList.add('event-none')
                    })
                })
            }
        }
    }, [props])

    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
    },[])
    
    return (
        <>
            <div className={`fs-18 question border border-3 p-3 rounded-4 mb-4 ${questionStatus}_border_es`}>
                <h6 className={`fs-18 fw-bold pb-2 pt-2 ${questionStatus}_text_es`}>Question {serialNo}
                    <span><button type="button" className="btn border-0 p-0 ms-2" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="focus hover" data-bs-template="<div class=&quot;popover shadow&quot; role=&quot;popover&quot;><div class=&quot;popover-arrow&quot;></div><div class=&quot;popover-inner pt-3 px-3 fw-medium &quot;>Click the box to change its arrow configuration. Clicking multiple times will cycle through the different options.<div class=&quot;row mt-2 &quot;><div class=&quot;col-sm-6 &quot;><div class='d-flex gap-2'><div><img src='/images/Number in Circle.png'class='img-fluid'></img></div></div><div><img src='/images/ex-onearrow.png'class='img-fluid pt-2'></img></div></div><div class=&quot; col-sm-6 &quot;><div class='d-flex gap-2'><div><img src='/images/Number in Circle (2).png'class='img-fluid'></img></div></div><div><img src='/images/ex-arrow.png'class='img-fluid pt-2'></img></div></div></div></div>"><svg className="icon ts-1p5"><use href="#icon_query"></use></svg></button></span>
                </h6>

                <span className="raw-question" id="question_box"><MathElement ele_id={`question_${question.order}_div`} elements={question.question}/></span>

                <div className="row my-3 custom_width_responsive">
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 fw-bold minw-40  my-2">4p</div>
                <div className="col-10 col-sm-11 col-md-11 col-lg-11">

                <div className="row">
                
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="4p"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="4p"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="4p"></button>
                   </div>
                </div>
                </div>
                </div>


                
                <div className="row my-3 custom_width_responsive">
                <div className=" col-1 col-sm-1 col-md-1 col-lg-1 fw-bold minw-40  my-2">3d</div>
                <div className=" col-10 col-sm-11 col-md-11 col-lg-11">

                <div className="row">

                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3d"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3d"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3d"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3d"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1 ">   
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3d"></button>
                    </div>
                </div>
                </div>
                </div>

                <div className="row  my-3 custom_width_responsive">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1  fw-bold minw-40  my-2">4s</div>
                    <div className=" col-10 col-sm-11 col-md-11 col-lg-11 ">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="4s"></button>
                    </div>
                </div>


                <div className="row  my-3 custom_width_responsive">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1  fw-bold minw-40  my-2">3p</div>
                    <div className=" col-10 col-sm-11 col-md-11 col-lg-11  ">
                    <div className="row">

                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3p"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3p"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3p"></button>
                    </div>
                </div>
               </div>
               </div>

                <div className="row my-3 custom_width_responsive">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1  fw-bold minw-40  my-2">3s</div>
                    <div className=" col-10 col-sm-11 col-md-11 col-lg-11  ">
                    <div className="row">
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="3s"></button>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="row my-3 custom_width_responsive">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1  fw-bold minw-40  my-2">2p</div>
                    <div className=" col-10 col-sm-11 col-md-11 col-lg-11 ">
                    <div className="row">
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="2p"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="2p"></button>
                    </div>
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="2p"></button>
                    </div>
                </div>
                </div>
                </div>
                <div className="row my-3 custom_width_responsive">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1  fw-bold minw-40  my-2">2s</div>
                    <div className=" col-10 col-sm-11 col-md-11 col-lg-11  ">
                    <div className="row">
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="2s"></button>
                    </div>
                </div>
                </div>
                </div>
            
                <div className="row my-3 custom_width_responsive">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1  fw-bold minw-40  my-2">1s</div>
                    <div className=" col-10 col-sm-11 col-md-11 col-lg-11">
                    <div className="row">
                    <div className="col-4 col-sm-2 col-md-2  col-lg-2 col-xl-1 py-1">
                        <button type="button" className="btn btn-outline-primary p-1 fs-5 minw-70 minh-40 shadow-none" onClick={(e) => addArrow(e)} data-edit="1s"></button>
                    </div>
                </div>
                </div>
                </div>

                {questionStatus !== 'default' &&
                    <div className="d-flex" id="why-section">
                        <span className="answer-response"><img src={questionStatus === 'correct' ? '/images/checkbox.png' : '/images/crossbox.png'} alt={questionStatus} /></span>
                        {question.reasoning && question.reasoning !== 'null' && 
                            <span><button type="button" className="btn ms-0 p-0 border-white text-blue text-decoration-underline ms-2 fw-bold" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-template={`<div class="popover shadow maxw-900 border-blue border border-2" role="popover"><div class="popover-arrow  border-blue "></div><div class="popover-inner"><div class="row "><div class="col-sm-4"><img src="/images/why-male.png"  alt="Male"/></div><div class="col-sm-8"><p class="mb-0 pt-2 px-3 fw-medium text-blue"></p><p class="mb-0 px-3 text-blue fw-medium">${JSON.parse(question.reasoning)}</p></div></div></div></div>`}>Why?</button></span>
                        }
                    </div>
                }

                <div className="button d-flex justify-content-between mt-4 ">
                    { questionStatus !== 'default' && question && question.solution_image && <button className="btn btn-outline-default btn-lg minw-90 m-2 ms-0" data-bs-target="#solutionModal" data-bs-toggle="modal">Solution</button> }
                    { questionStatus !== 'default' && <span className={`fw-bold mt-3 fs-5 ${questionStatus}_text_es`}>{progressText}</span> }
                    { props.mode && props.mode === 'practice' && questionStatus === 'default' && <button type="button" className={`btn btn-lg ms-auto btn-theme-primary m-2 me-0 ${!enableAnswerButton && 'disabled'}`} onClick={() => checkAnswer()} >Check</button> }
                    { ((props.mode && props.mode === 'exam') || questionStatus !== 'default') && <button className={`btn btn-lg m-2 ms-auto me-0 ${props.mode === 'exam' && 'btn-theme-primary'} ${questionStatus === 'correct' ? 'btn-green' : (questionStatus === 'incorrect' ? 'btn-danger' : 'border-blue') }`} onClick={() => submitAnswer()}>Next</button> }
                </div> 
            </div>

            <QuestionSolution image={props && props.question && props.question.solution_image ? props.question.solution_image : ''} />
        </>
    )
}

export default EnergyDiagram;